import React from 'react';
import { MdCloudDownload , MdDelete } from 'react-icons/md';
import { format } from 'date-fns';
import { API_URL } from '../../../actions';

export default function DocumentRow({ file  , styles , deleteFile = null }) {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  return (
    <div className={`${styles['list-row']} ${styles['list-item']}`}>
      <a href={`${API_URL}/file/download/${file._id}?token=${token}`} target="_blank" rel="noreferrer"className={styles['col']}>
        <div className={styles.download}>
          <MdCloudDownload size={20} />
        </div>
        <p className="bold"><span className="uppercase">{file.title}</span></p>
      </a>
      <div className={styles['col']}>
        <p><span className="uppercase">{file?.createdAt && format(new Date(file.createdAt) , 'dd/MM/yyyy HH:mm')}</span></p>
      </div>
      <div className={`${styles['col']} ${styles.delete}` }>
        {deleteFile &&
          <button
            onClick={() => deleteFile(file._id)}
            >
            <p>supprimer</p>
            <MdDelete size={20} />
          </button>
        }
      </div>
    </div>
)
}
