import React from 'react';
import { Link } from 'react-router-dom';

export default function CaseRow({ styles , data }) {
  const entityId = data?.entities?.length > 0 && data?.entities[0]?._id;
  return (
    <Link
      to={entityId ? `/admin/cases/${data._id}/${entityId}` : `/admin/cases/${data._id}/`}
      className={`${styles['list-row']} ${styles['list-item']}`}
    >
      <div className={styles['col']}>
        <p className="bold">{data?.name}</p>
      </div>
      <div className={styles['entities']}>
        {data?.entities?.map((c) => <p key={c._id}>{c.name}</p>)}
      </div>
      <div className={styles['users']}>
        {data?.users?.map((u) => <div
            key={u._id}
            className={styles.user}
          >
          <p>{u?.profile?.lastName?.charAt(0)}.{u?.profile?.firstName?.charAt(0)}</p>
        </div>)}
      </div>
    </Link>
  );
}
