import React , { useState } from 'react';
import style from './CheckBox.module.scss';

export default function CheckBox({ label , name , defaultValue , onChange }) {
  const [checked , setChecked ] = useState(defaultValue);

  function HandleChange(val) {
    setChecked(val);
    onChange(val)
  }

  return (
    <label htmlFor={name} className={style.container}>
      <p>{label}</p>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={(e) => HandleChange(e.target.checked)}/>
      <span className={style.checkmark}></span>
    </label>
  )
}
