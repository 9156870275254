import React , { useState , useEffect } from 'react';
import styles from './Input.module.scss';

export default function Input({ onChange , name , label , defaultValue = "" , value , withErrors , error , register , placehloder , type , ...rest }) {

  const [ inputValue , setInputValue ] = useState(defaultValue);

  useEffect(() => {
    if (value || typeof value === "number") setInputValue(value);
  }, [value]);

  function handleChange(val) {
    if (onChange) {
      onChange(val);
    }
    setInputValue(val)
  }

  return (
    <div className={styles.input}>
      <label htmlFor={name}>{label}</label>
      {!register ? 
        <>
          <input
            name={name}
            type={type}
            placeholder={placehloder}
            value={inputValue}
            defaultValue={defaultValue}
            onChange={(e) => handleChange(e.target.value)}
            />
        </>
        :
        <>
          <input
            name={name}
            type={type}
            className={error ? styles.error : ''}
            placeholder={placehloder}
            onChange={ onChange ? (e) => onChange(e.target.value) : null }
            defaultValue={defaultValue}
            ref={register}
            {...rest}
            />
        </>
      }
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  )
}
