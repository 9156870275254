import React, { useEffect , useCallback } from "react";
import { Switch, Route , useHistory } from "react-router-dom";

import { useGlobalContext } from '../context/GlobalContext';

import { IS_AUTH, USER_AUTH } from "../actions/types";

import { getUserAuthAction } from '../actions/authActions';
import { setVhUnit } from '../utils/utils';

import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import Toaster from "./partials/Toaster/Toaster";
import Index from "./pages/Auth/SwitchIndex";
import AdminRouter from "./pages/Admin/AdminRouter";
import MemberRouter from "./pages/Member/MemberRouter";

export default function GlobalRoutes() {

  const [ context, dispatch ] = useGlobalContext();

  const history = useHistory();

  var token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    if(token) {
      getUser();
    }
    setVhUnit();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(context.auth.error === 401) {
      logout();
    }
  // eslint-disable-next-line 
  }, [context.auth.error]);

  function logout() {
    history.push("/login");
    localStorage.removeItem('token');
    dispatch({
      type : IS_AUTH,
      payload : false
    });
    dispatch({
      type : USER_AUTH,
      payload : null
    });
  };

  return (
    <>
      <Switch>

        <Route exact path="/" render={
            (props) => <Index />
          }/>

        <Route path="/login" render={
          (props) => <Login
          {...props}
          />
        }/>

        <Route path="/admin/:page?/:id?" render={
          (props) => <AdminRouter
            logout={logout}
            {...props}
          />
        }/>

        <Route path="/member/:page?/:entityId?/:type?/:id?" render={
          (props) => <MemberRouter
            logout={logout}
            {...props}
          />
        }/>

        <Route exact path="/reset-password/:token" render={
          (props) => <ResetPassword
          {...props}
          />
        }/>
      </Switch>
      <Toaster toast={context.auth.toast} dispatch={dispatch}/>
    </>
  )
}
