import { getData , putData , postData , deleteData } from './index';

import {
  ERROR_MEETS,
  GET_MEETS,
  GET_MEET,
  POST_MEET,
  PUT_MEET,
  POST_CALENDAR,
  GET_CALENDAR,
} from "./types"


export async function getMeetsListAction(dispatch) {
  let url = "/appointment";
  var meets;

  await getData(ERROR_MEETS, url, dispatch , true ).then((response)=>{
    meets = response.appointments
  });

  dispatch({
    type : GET_MEETS,
    payload : meets
  });

};

export async function getMeetAction(dispatch , id) {
  
  let url = "/appointment/" + id ;
  var meet;

  await getData(ERROR_MEETS, url, dispatch , true ).then((response)=>{
    meet = response
  });

  dispatch({
    type : GET_MEET,
    payload : meet
  });

};

export async function postMeetAction(dispatch , obj){
  let url = "/appointment";
  var createMeet;

  await postData(POST_MEET , ERROR_MEETS, url, dispatch , obj, true ).then((response)=>{
    createMeet = response
  });

  dispatch({
    type : POST_MEET ,
    payload : createMeet
  });

  dispatch({
    type : 'SET_TOAST' ,
    payload : {
      message:'le rendez-vous a été enregistré',
      type: 'success'
    }
  });

};

export async function putMeetAction(dispatch , id , data ) {
  let url = "/appointment/" + id;
  var meet;

  await putData(PUT_MEET, ERROR_MEETS ,  url , dispatch, data , true ).then((response)=>{
    meet = response
  });

  dispatch({
    type : PUT_MEET,
    payload : meet
  });

  dispatch({
    type : 'SET_TOAST' ,
    payload : {
      message:'les modifications ont été enregistrée',
      type: 'success'
    }
  });

};



export async function deleteMeetAction(dispatch , id ){
  let url = "/appointment/" + id;
  let deleted;

  await deleteData(ERROR_MEETS, url, dispatch  ).then((response)=>{
    deleted = response
  });

  if (deleted) {
    dispatch({
      type : 'SET_TOAST' ,
      payload : {
        message:'le rendez-vous a été supprimé',
        type: 'success'
      }
    });
  }

};

export async function getCalendarAction(dispatch) {
  let url = "/calendar";
  var calendar;

  await getData(ERROR_MEETS, url, dispatch ).then((response)=>{
    calendar = response.calendar
  });

  dispatch({
    type : GET_CALENDAR,
    payload : calendar
  });

};

export async function postCalendarAction(dispatch , data , date ) {
  let url = "/calendar" ;
  var meet;

  await postData( POST_CALENDAR , ERROR_MEETS ,  url , dispatch, data , true ).then((response)=>{
    meet = response
  });

  dispatch({
    type : POST_CALENDAR,
    payload : {
      date : date,
      message: meet.message
    }
  });

};

