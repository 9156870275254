import React from 'react';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';
import Input from '../../../../../partials/Form/Input/Input';
import styles from './Question.module.scss';

export default function Question({ question , options , handleChange , type,  value }) {

  return (
    <div className={styles.container}>
      <label>{question}</label>
      {type === "list" &&
        <div className={styles.options}>
          {options.map(( item , i ) =>
            <button
              type="button"
              key={`option${i}`}
              className={`${styles.btn} ${item.value === value ? styles['is-active'] : ""}`}
              onClick={() => handleChange(item.value)}
              >{item.label}
            </button>
          )}
        </div>  
      }
      {type ===  "select" &&
        <div className={styles.select}>
          <CustomSelect
            options={options}
            defaultValue={options.find(opt => opt.value === value)}
            handleChange={(opt) => handleChange(opt.value)}
            />
        </div>  
      }
      {type === "number" &&
        <div className={styles.input}>
          <Input
            value={value || 0}
            type="number"
            onChange={(val) => handleChange(parseFloat(val))} />
        </div>  
      }        
    </div>
  )
}

