import { getData , putData , getFile, postData } from './index';

import {
  ERROR_QUOTES,
  GET_QUOTES,
  GET_QUOTE,
  PUT_QUOTE,
  POST_QUOTE,
  SET_TOAST,
  GET_QUOTES_FILTERED,
  // LAUNCH_MISSION
} from "./types"


export async function getQuotesListAction(dispatch) {
  let url = "/quote";
  var quotes;

  await getData(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    quotes = response.quotes
  });

  dispatch({
    type : GET_QUOTES,
    payload : quotes
  });

};

export async function postQuotesFilteredAction(dispatch , data ) {
  let url = "/quote/filtered";
  var quote;

  await postData( GET_QUOTES_FILTERED , ERROR_QUOTES ,  url , dispatch, data , true ).then((response)=>{
    quote = response
  });

  dispatch({
    type : GET_QUOTES_FILTERED,
    payload : quote
  });

};

export async function getQuoteAction(dispatch , id) {
  
  let url = "/quote/" + id ;
  var quote;

  await getData(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    quote = response
  });

  dispatch({
    type : GET_QUOTE,
    payload : quote
  });

};

export async function sendMissionLetterToSignatureAction(dispatch , type , id) {
  
  let url = `/quote/${id}/missionletter${type}/signature`;
  var missionletter;

  await getData(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    missionletter = response
  });

  console.log(missionletter)

  dispatch({
    type : SET_TOAST,
    payload : {
      type: "success",
      message: "la lettre de mission a été envoyée"
    }
  });

};

export async function getMissionLetterAction(dispatch , id) {
  
  let url = `/quote/${id}/missionletter`;
  let data;

  await getFile(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    data = response
  });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(data);
  link.download = "lettre de mission";
  link.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  );
  window.URL.revokeObjectURL(data);
  link.remove();

};

export async function launchMissionAction(dispatch , id) {
  
  let url = `/quote/${id}/launch`;
  var missionLaunched;

  await getData(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    missionLaunched = response
  });

  if (missionLaunched) {
    dispatch({
      type : SET_TOAST,
      payload : {
        type: "success",
        message: "Votre compte est activé"
      }
    });
  }
};

export async function putQuoteAction(dispatch , id , data ) {
  let url = "/quote/" + id;
  var quote;

  await putData(PUT_QUOTE, ERROR_QUOTES ,  url , dispatch, data , true ).then((response)=>{
    quote = response
  });

  dispatch({
    type : PUT_QUOTE,
    payload : quote
  });

  dispatch({
    type : SET_TOAST,
    payload : {
      type: "success",
      message: "Le devis à été modifié"
    }
  });

};

export async function postQuoteAction(dispatch , data ) {
  let url = "/quote";
  var quote;

  await postData( POST_QUOTE , ERROR_QUOTES ,  url , dispatch, data , true ).then((response)=>{
    quote = response
  });

  dispatch({
    type : SET_TOAST,
    payload : {
      type: "success",
      message: "Le devis à été crée"
    }
  });

  dispatch({
    type : POST_QUOTE,
    payload : quote
  });

};



