import React ,{ useCallback , useEffect  , useState } from 'react';
import { GoPlus } from 'react-icons/go';

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { exportUsersAction, getUsersListAction } from '../../../../../actions/usersActions';

import Input from '../../../../partials/Form/Input/Input';
import SortButton from '../../../../partials/SortButton/SortButton';
import styles from './UserListPage.module.scss';
import UserRow from './UserRow/UserRow';
import Modale from '../../../../partials/Modale/Modale';
import UserModale from '../../../../partials/UserModale/UserModale';
import Button from '../../../../partials/Button/Button';

export default function UserListPage() {

  const [ context , dispatch ] = useGlobalContext();
  const authId = context?.auth?.userAuth?._id;
  const usersList = context?.users?.usersList;

  const [ isUserModale , setIsUserModale ] = useState();
  const [ filtredList , setFiltredList ] = useState();
  const [ search , setSearch ] = useState('');

  const initSortState = {
    name: true,
    cases: true,
  }

  const [ sortByReverse , setSortByReverse ] = useState(initSortState);

  const getDatas = useCallback(() => {
    getUsersListAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    if (usersList) {
      setFiltredList(usersList);
    }
  },[usersList]);

  useEffect(() => {
    if (search) {
      let searchList = usersList?.filter(item => {
        const casesNames = item.cases.map((c) => c.name).sort((a, b) => a.localeCompare(b)).join(',');
        if (casesNames.toLowerCase().includes(search.toLowerCase())) return true;
        if (item.profile.lastName.toLowerCase().includes(search.toLowerCase())) return true;
        if (item.profile.firstName.toLowerCase().includes(search.toLowerCase())) return true;
        if (item.company && item.company.name.toLowerCase().includes(search.toLowerCase())) return true;
        return false;
      })
      setFiltredList(searchList)
    } else {
      setFiltredList(usersList)
    }
    // eslint-disable-next-line 
  },[search]);

  function sortBy(type , isReverse ) {
    let list = filtredList;
    switch (type) {

      case 'name':
        list = list.sort((a , b ) => {
          if (a.profile.lastName < b.profile.lastName) return -1;
          if (a.profile.lastName > b.profile.lastName) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , name: isReverse })
        break;
      
      case 'cases':
        list = list.sort((a , b ) => {
          const casesNamesA = a.cases.map((c) => c.name).sort((a, b) => a.localeCompare(b)).join(',');
          const casesNamesB = b.cases.map((c) => c.name).sort((a, b) => a.localeCompare(b)).join(',');
          if (casesNamesA < casesNamesB) return -1;
          if (casesNamesA > casesNamesB) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , cases: isReverse })
        break;
      
      default:
        break;
    }
  }

  return (
    <>
    <div className="page-content">
      <div className={`${styles['header']}`}>
        <div className={styles['row']}>
          <div className={styles['col-3']}>
            <Input
              value={search}
              onChange={(val) => setSearch(val) }
              placehloder="Recherche"
            />
          </div>
          <div className={styles['add']}>
            <Button
              type="button"
              primary
              onClick={() => exportUsersAction(dispatch)}
              >
              EXPORTER
            </Button>
            <Button
              type="button"
              primary
              onClick={() => setIsUserModale({})}
              >
              <GoPlus />NOUVEAU UTILISATEUR
            </Button>
          </div>
        </div>
        <div className={`${styles['list-row']} ${styles['sort']}`}>
          <SortButton name={"Nom Prénom"} action={() => sortBy('name' , !sortByReverse.name )} state={sortByReverse.name} className={styles['col']}/>
          <SortButton name={"Dossiers"} action={() => sortBy('cases' , !sortByReverse.cases )} state={sortByReverse.cases} className={styles['cases']}/>
        </div>
      </div>
      <div className={styles['list']}>
        {filtredList?.filter((u) => u._id !== authId).map((user) => <UserRow
            key={user._id}
            styles={styles}
            user={user}
            handleClick={setIsUserModale}
          />
        )}
      </div>
    </div>
    <Modale
      isOpen={isUserModale ? true : false}
      modaleToggle={setIsUserModale}
      >
      {isUserModale
        && <UserModale
          user={isUserModale}
          setModaleIsActive={setIsUserModale}
        />
      }
    </Modale>
  </>
  )
}
