import React , { useEffect , useCallback } from 'react';
import { Link , useParams } from 'react-router-dom';

import { useGlobalContext } from '../../../../context/GlobalContext';

import { getQuoteAction } from '../../../../actions/quotesActions';
import { API_URL } from '../../../../actions/index';

import { labels } from '../../../../utils/constants';

import { HiArrowLeft  } from 'react-icons/hi';

import styles from './QuotePage.module.scss';

export default function QuotePage() {

  const [ context , dispatch ] = useGlobalContext();
  const { quoteId , entityId } = useParams();

  const { quote } = context.quotes;
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  
  const getData = useCallback(() => {
    getQuoteAction(dispatch , quoteId);
  },[dispatch , quoteId]);

  useEffect(() => {
    getData()
  },[getData]);

  return (

    <div className={`page-content member quote ${styles.container} `}>
      {quote &&
        <>
          <header className={`${styles.header}`}>
            <Link to={`/member/tools/${entityId}`} className={styles.back} >
              <HiArrowLeft  size={20}/>  <span className="bold">Retour aux outils</span>
            </Link>
            
            <div className={styles.content}>
              
              <div>

              </div>

              <div className={`${styles.row} ${styles.profile} ${styles.quote}`}>
                <div className={`${styles['col-2']}  ${styles.number}`}>
                  <p className={styles.label}>Devis n°</p>
                  <h1 className="bold">{quote.number}</h1>
                </div>

                <div className={`${styles['col-4']}`}>
                  <p className={styles.label}>Type d’activité</p>
                  <p className="bold">{quote.activity}</p>
                </div>
              </div>

              <div className={`${styles.row}  ${styles.profile} ${styles.quote}`}>

                <div className={`${styles['col-4']}`}>
                  <p className={styles.label}>Société</p>
                  <p className="bold">{quote?.client?.name}</p>
                </div>

                {quote?.client?.siret &&
                  <div className={`${styles['col-4']}`}>
                    <p className={styles.label}>N°Siret</p>
                    <p className="bold">{quote?.client?.siret}</p>
                  </div>
                }

              </div>

            </div>
          </header>
          <main className={`${styles.main}`}>
            <div className={styles.content}>
              <section>
                <div className={`${styles.row}`}>
                  {quote?.isTaxed &&
                    <div className={`${styles['col-2']}`}>
                      <p className={styles.label}>Type d’exercice</p>
                      <p className="bold">{labels['isTaxed'][quote.isTaxed]}</p>
                    </div>
                  }
                  {quote?.propertyNumber &&
                    <div className={`${styles['col-4']}`}>
                      <p className={styles.label}>Nombre de biens</p>
                      <p className="bold">{quote?.propertyNumber}</p>
                    </div>
                  }
                  {quote?.tvaPeriodicity &&
                    <div className={`${styles['col-4']}`}>
                      <p className={styles.label}>TVA</p>
                      <p className="bold">{labels['tvaPeriodicity'][quote.tvaPeriodicity]}</p>
                    </div>
                  }
                </div>
              </section>

              <section>
                
                <h3>Documents</h3>
                
                <div className={`${styles.row} ${styles.documents}`}>
                  {quote?.missionLetter?._id &&
                    <div className={`${styles['col-2']}`}>
                      <p className={styles.label}>Lettre de mission</p>
                      <div className={styles.doc}>
                        <a href={`${API_URL}/file/download/${quote.missionLetter._id}?token=${token}`} target="_blank" rel="noreferrer">
                          {quote.missionLetter.title}
                        </a>
                      </div>
                    </div>
                  }
                  {quote?.contract?._id &&
                    <div className={`${styles['col-2']}`}>
                      <p className={styles.label}>Contrat</p>
                      <div className={styles.doc}>
                        <a href={`${API_URL}/file/download/${quote.contract._id}?token=${token}`} target="_blank" rel="noreferrer">
                          {quote.contract.title}
                        </a>
                      </div>
                    </div>
                  }
                </div>

              </section>
            </div>
          </main>
        </>
      }
    </div>
  )
}
