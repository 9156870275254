import { getData , postData, putData } from './index';
import { utils, writeFile } from 'xlsx';

import {
  ERROR_CASES,
  GET_CASES,
  GET_CASE,
  PUT_CASE,
  POST_CASE,
  SET_TOAST,
  GET_CASES_FILTERED,
  GET_CASES_LIST,
  // POST_CASE,
} from "./types";


export async function getCasesAction(dispatch) {
  let url = "/case";
  var cases;

  await getData(ERROR_CASES, url, dispatch , true ).then((response)=>{
    cases = response.cases;
  });

  dispatch({
    type : GET_CASES,
    payload : cases,
  });

};

export async function getCasesListsAction(dispatch) {
  let url = "/case/list";
  var lists;

  await getData(ERROR_CASES, url, dispatch , true ).then((response)=>{
    lists = response.lists;
  });

  dispatch({
    type : GET_CASES_LIST,
    payload : lists,
  });

};


export async function postCasesFilteredAction(dispatch , data ) {
  let url = "/case/filtered";
  var cases;

  await postData( GET_CASES_FILTERED , ERROR_CASES ,  url , dispatch, data , true ).then((response)=>{
    cases = response;
  });


  dispatch({
    type : GET_CASES_FILTERED,
    payload : cases,
  });

};

export async function getCaseAction(dispatch , id) {
  
  let url = `/case/${id}` ;
  var res;

  await getData(ERROR_CASES, url, dispatch , true ).then((response)=>{
    res = response.case;
  });

  dispatch({
    type : GET_CASE,
    payload : res,
  });

};

export async function postCaseAction(dispatch , data ) {
  let url = "/case" ;
  let res;

  await postData( POST_CASE , ERROR_CASES ,  url , dispatch, data , true ).then((response)=>{
    res = response.case;
  });

  dispatch({
    type : POST_CASE,
    payload : res
  });

  dispatch({
    type : SET_TOAST,
    payload : {
      type: "success",
      message: "le dossier a été créé"
    }
  });

};

export async function putCaseAction(dispatch , id , data ) {
  let url = `/case/${id}` ;
  var res;

  await putData(PUT_CASE, ERROR_CASES ,  url , dispatch, data , true ).then((response)=>{
    res = response.case;
  });

  dispatch({
    type : PUT_CASE,
    payload : res
  });

};


export async function exportCasesAction(dispatch) {
  let url = "/export/cases";
  var cases;

  await getData(ERROR_CASES, url, dispatch , true ).then((response)=>{
    cases = response.obj.data;
  });
  const worksheet = utils.json_to_sheet(cases);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Dossier");
  writeFile(workbook, "dossiers.xlsx", { compression: true });

};

