import React , { useEffect , useCallback , } from 'react';

import { getUserAuthAction } from '../../../../actions/authActions';
import { useGlobalContext } from '../../../../context/GlobalContext';

import ze from '../../../../assets/images/outil1.svg'
import silae from '../../../../assets/images/outil2.svg'
// import tiime from '../../../../assets/images/outil3.svg'
import UserInfo from '../partials/UserInfo/UserInfo'
import styles from './ToolsPage.module.scss'

export default function ToolsPage() {

  const [ context , dispatch ] = useGlobalContext();

  const { userAuth } = context.auth ;

  const getProfile = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const tools = [
    {
      url: "https://apps.tiime.fr/signin",
      img: ze,
      name: ['ZE' , 'COMPTABLE'],
      title : "Zecomptable (Tiime)",
      text: "Accédez à votre plateforme Tiime Account et Tiime Invoice pour gérer votre facturation et envoyer vos justificatifs"
    },
    {
      url: "https://www.silaexpert04.fr/silae",
      img: silae,
      name: ['ZE' , 'PAIE'],
      title : "Accès à votre plateforme SILAE",
      text: "Outil de gestion des paies"
    },
    // {
    //   url: "/",
    //   img: tiime,
    //   name: ['Votre plateforme' , 'TIIME'],
    //   title : "Accès à votre plateforme TIIME",
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in laoreet diam. Maecenas sollicitudin."
    // },
  ]

  return (
    <div className="page-content member">
      <UserInfo />
      <h1>Mes outils</h1>
      <div className={styles.row}>
      {tools.map((t, i) =>  {
        return (
          <a href={t.url} key={'tool' + i } className={`${styles.card} ${!userAuth?.stripeId ? styles.disabled:''}`}>
            <div className={styles.image}>
              <p><i>{t.name[0]}</i><br/><span className="bold">{t.name[1]}</span></p>
              <img src={t.img} alt={t.title} />
            </div>
            <div className={styles.text}>
              <h3 className="bold">{t.title}</h3>
              <p>{t.text}</p>
            </div>
          </a>
        )
      })}
      </div>
    </div>
  )
}
