import React , { useEffect , useCallback  , useState } from 'react';
import { useForm } from "react-hook-form";

import { putUserAuthAction , getUserAuthAction } from '../../../../actions/authActions';
import { useGlobalContext } from '../../../../context/GlobalContext';
import Input from '../../../partials/Form/Input/Input';
import Button from '../../../partials/Button/Button';
import styles from './AccountPage.module.scss';

export default function AccoutPage() {

  const [ context , dispatch ] = useGlobalContext();

  const { handleSubmit, register, setValue , errors } = useForm();

  const [ displayPassword , setDisplayPassword ] = useState(false);

  const { userAuth } = context.auth ;

  const getProfile = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, [context.auth.toast]);

  const submitForm = (values)  => {
    putUserAuthAction(dispatch , values);
  };

  return (
    <div className={`page-content member ${styles.container}`}>
      <div className={styles.account}>
      {userAuth && userAuth.email &&
        <form onSubmit={handleSubmit(submitForm)}>
          <h1 className="bold">Mes informations</h1>
          <div className={styles.row}>
            <div className={styles.col}>
              <Input
                label={"Nom"}
                name={"lastName"}
                defaultValue={userAuth.profile.lastName}
                onChange={(val) => setValue('lastName' , val)}
                register={register({
                  required: "Compléter votre nom",
                })}
                withErrors={true}
                error={errors.lastName && errors.lastName.message}
              />
            <Input
              label={"Prénom"}
              name={"firstName"}
              defaultValue={userAuth.profile.firstName}
              onChange={(val) => setValue('firstName' , val)}
              register={register({
                required: "Compléter votre prénom",
              })}
              withErrors={true}
              error={errors.firstName && errors.firstName.message}
              />
            </div>

            <div className={styles.col}>
              <Input
                label={"Mail"}
                name={"email"}
                defaultValue={userAuth.email}
                onChange={(val) => setValue('email' , val)}
                register={register({
                  required: "Compléter votre email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Adresse mail invalide"
                  }
                })}
                withErrors={true}
                error={errors.email && errors.email.message}
                />

              <Input
                label={"Téléphone"}
                name={"tel"}
                defaultValue={userAuth.profile.tel}
                onChange={(val) => setValue('tel' , val)}
                register={register({
                  required: "Compléter votre numéro de téléphone",
                })}
                withErrors={true}
                error={errors.tel && errors.tel.message}
                />    
            </div>

          </div>

          {displayPassword &&
              <div className={styles.row}>
                <div className={styles.col}>
                  <Input
                    label={"Nouveau mot de passe"}
                    name={"password"}
                    type={"password"}
                    onChange={(val) => setValue('password' , val)}
                    register={register({
                      required: "Compléter le mot de passe",
                    })}
                    withErrors={true}
                    error={errors.password && errors.password.message}
                    />   
                </div>
              </div>
            }

          <div className={styles.btns}>
          <Button
            onClick={() => setDisplayPassword(!displayPassword)}
            primary
            outline
            >
            Changer mon mot de passe
          </Button>

          <Button
            type="submit"
            className="uppercase"
            primary>
            Valider
          </Button>

          </div>

        </form>
      }
      </div>
    </div>
  )
}
