import React , { useCallback , useEffect } from 'react';
import { Switch, Route , useLocation , useParams } from "react-router-dom";
import { useTransition, animated } from 'react-spring';
import { useGlobalContext } from '../../../context/GlobalContext';
import { getUserAction } from '../../../actions/usersActions';

import AccoutPage from './Account/AccountPage';
import ToolsPage from './Tools/ToolsPage';
import DocumentsPage from './Documents/DocumentsPage';
import Nav from './partials/Nav/Nav';
import QuotePage from './QuotePage/QuotePage';
import { getEntityAction } from '../../../actions/entityActions';

export default function MemberRouter({ logout }) {

  const [ context , dispatch ] = useGlobalContext();
  const { entityId } = useParams();

  const { userAuth } = context.auth;

  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 1, transform: 'translate3d(0%,120vh, 0) scale(0.8)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0px,0) scale(1)' },
    leave: { opacity: 1, transform: 'translate3d(0%, -120vh, 0) scale(0.8)'},
    config: { duration: 400 , tension:	400 }
  });

  const getUser = useCallback(() => {
    getUserAction(dispatch , userAuth._id);
  },[dispatch , userAuth]);

  const getEntity = useCallback(() => {
    getEntityAction(dispatch , entityId);
  },[dispatch , entityId]);

  useEffect(() => {
    if (userAuth?.role === "Member") {
      getUser();
    }
    if (userAuth?.role && userAuth.role !== "Member") {
      logout();
    }
    // eslint-disable-next-line 
  }, [userAuth]);

  useEffect(() => {
    if (entityId) {
      getEntity();
    }
  }, [entityId , getEntity])

  return (
    <div className="page-container">
      <Nav
        logout={logout}
        />
      {transitions.map(({ item: location, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={location}>

            <Route exact path={`/member/account/:entityId`} render={
              () => <AccoutPage />
            }/>

            <Route exact path={`/member/tools/:entityId`} render={
              () => <ToolsPage />
            }/>

            <Route exact path={`/member/tools/:entityId/quote/:quoteId`} render={
              () => <QuotePage />
            }/>

            <Route exact path={`/member/documents/${entityId}`} render={
              () => <DocumentsPage />
            }/>

          </Switch>
        </animated.div>
      ))}
    </div>
  )
}