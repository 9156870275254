import React from 'react';
import { Controller, useForm } from "react-hook-form";

import { useGlobalContext } from '../../../context/GlobalContext';

import Input from '../Form/Input/Input';
import Button from '../Button/Button';

import styles from './EntityModale.module.scss';
import { putEntityAction, postEntityAction } from '../../../actions/entityActions';
import { activitiesOptions } from '../../../utils/constants';
import CustomSelect from '../Form/Select/CustomSelect';

export default function EntityModale({  entity , caseId, setModaleIsActive }) {

  const dispatch = useGlobalContext()[1];

  const {
    handleSubmit,
    register,
    errors,
    setValue,
    control,
    watch,
  } = useForm({
    defaultValues: entity,
  });

  function submitForm(values) {
    if (entity._id) {
      putEntityAction(dispatch, entity._id , {...values, case: caseId });
    } else {
      postEntityAction(dispatch, {...values, case: caseId });
    }
    if (Object.entries(errors).length === 0) {
      setModaleIsActive();
    }
  };

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>{entity._id ? "Modifier l'entité" : 'Nouvelle entité'}</h2>
        <form onSubmit={handleSubmit(submitForm)}>
          <Input
            label={"Nom"}
            name={"name"}
            defaultValue={entity?.name}
            register={register({
              required: "Compléter le nom",
            })}
            withErrors={true}
            error={errors?.name?.message}
          />

          <Input
            label={"Siret"}
            name={"siret"}
            defaultValue={entity?.siret}
            register={register({
              required: "Compléter le numéro de siret",
            })}
            withErrors={true}
            error={errors?.siret?.message}
          />

          <Controller
            name='activity'
            rules={{ required: "l'activité est requise" }}
            control={control}
            defaultValue={activitiesOptions.find((opt) => opt.value === watch('activity') || null)}
            render={() =>
              <CustomSelect
                label={"Activité"}
                defaultValue={activitiesOptions.find((opt) => opt.value === watch('activity'))}
                handleChange={(opt) => setValue('activity', opt.value)}
                options={activitiesOptions}
                withErrors={true}
                error={errors?.activity?.message}
                />
            }
          />

          <Input
            label={"Stripe Id"}
            name={"stripeId"}
            defaultValue={entity?.stripeId}
            withErrors={true}
          />

          <div className={styles.contact}>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <Input
                  label={"Nom"}
                  name={"lastName"}
                  register={register}
                  defaultValue={entity?.lastName}
                  withErrors={true}
                />
                <Input
                  label={"Prénom"}
                  name={"firstName"}
                  register={register}
                  defaultValue={entity?.firstName}
                  withErrors={true}
                />
                <Input
                  label={"Mail"}
                  name={"email"}
                  defaultValue={entity?.email}
                  register={register({
                    required: "Compléter votre email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Adresse mail invalide"
                    }
                  })}
                  withErrors={true}
                  error={errors?.email?.message}
                />
                <Input
                  label={"Tel."}
                  name={"tel"}
                  defaultValue={entity?.tel}
                  register={register({
                    required: "Compléter le numéro de téléphone",
                  })}
                  withErrors={true}
                  error={errors?.tel?.message}
                />
              </div>
              <div className={styles['col-2']}>
                <Input
                  label={"Rue"}
                  name={"adress.street"}
                  register={register}
                  defaultValue={entity?.adress?.street}
                  withErrors={true}
                />

                <Input
                  label={"Ville"}
                  register={register}
                  name={"adress.city"}
                  defaultValue={entity?.adress?.city}
                  withErrors={true}
                />

                <Input
                  label={"Pays"}
                  register={register}
                  name={"adress.country"}
                  defaultValue={entity?.adress?.country}
                  withErrors={true}
                />

                <Input
                  label={"CP"}
                  register={register}
                  name={"adress.cp"}
                  defaultValue={entity?.adress?.cp}
                  withErrors={true}
                />

              </div>

            </div>


            

            <div className={styles.actions}>
              <Button
                type={"submit"}
                primary
                >
                Valider
              </Button>
            </div>
          </div>
        </form>
    </div>
  )
}
