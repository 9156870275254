import React , { useState ,useEffect , useCallback } from 'react';
import { Link , useParams , useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { format } from 'date-fns';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { getMeetAction , putMeetAction , deleteMeetAction } from '../../../../../actions/meetsActions';

import { HiArrowLeft , HiUser } from 'react-icons/hi';

import Button from '../../../../partials/Button/Button';
import Textarea from '../../../../partials/Form/Textarea/TextArea';
import Modale from '../../../../partials/Modale/Modale';
import DocumentModale from '../../../../partials/DocumentModale/DocumentModale';

import styles from './MeetPage.module.scss';
import { getEntityAction } from '../../../../../actions/entityActions';

export default function MeetPage() {

  const { handleSubmit, register, setValue } = useForm();

  const [ context , dispatch ] = useGlobalContext();
  const { meetId } = useParams();
  const history = useHistory();

  const { meet } = context.meets ;
  const { entity } = context.cases;

  const [ fileModaleIsActive  , setFileModaleIsActive ] = useState();
  
  const getData = useCallback(() => {
    getMeetAction(dispatch , meetId);
  },[dispatch , meetId]);

  useEffect(() => {
    getData()
    return () => {
      dispatch('CLEAR_USER')
      dispatch('CLEAR_MEET')
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (meet?.client?._id) getEntityAction(dispatch , meet?.client?._id );
    // eslint-disable-next-line
  },[meet?.client?._id])

  useEffect(() => {
    setValue('cr' , meet?.cr ?  meet.cr : "" )
    setValue('note' , meet?.note ? meet.note : "" )
    // eslint-disable-next-line
  }, [meet])

  const submitForm = (values)  => {
    putMeetAction(dispatch , meet._id , values )
  };

  const deleteMeet = (id) => {
    history.push('/admin/meet');
    deleteMeetAction(dispatch , id );
  }

  var options = { weekday: "long", year: "numeric", month: "long", day: "numeric"};

  return (

    <div className={`page-content ${styles.container} `}>
      {meet &&
        <>
          <header className={`${styles.header}`}>

            <Link to={'/admin/meet'} className={styles.back} >
              <HiArrowLeft  size={20}/>  <span className="bold">Retour à la liste</span>
            </Link>

            <div className={styles.content}>

              <div className={`row ${styles.profile} ${styles.quote}`}>
                <div className={`col-2 ${styles.number}`}>
                  <p className={styles.label}></p>
                  <h1 className="bold">{entity?.name}</h1>
                </div>
              </div>

              <div className={`row ${styles.profile} ${styles.quote}`}>


                {entity?.siret &&
                  <div className="col-4">
                    <p className={styles.label}>N°Siret</p>
                    <p className="bold">{entity?.siret}</p>
                  </div>
                }

              </div>

            </div>
          </header>
          <main className={`${styles.main}`}>
            <div className={styles.content}>
              <form onSubmit={handleSubmit(submitForm)}>

              {entity?.quotes?.length > 0 && 
                <section>
                  <h3>Devis en cours</h3>
                  <div className={styles.quotes}>
                  {entity?.quotes.map((q , i) =>
                    <Link key={"link" + i } to={`/admin/quote/view/${q._id}`} className={styles.quote}>
                      <p>Devis n°{q.number}</p>
                    </Link>
                  )}
                  </div>
                </section>
              }

              <section>
                <h3>Compte-rendu</h3>
                <Textarea
                  name={"cr"}
                  placeholder={""}
                  onChange={(val) => setValue('cr' , val )}
                  defaultValue={meet.cr ? meet.cr : ''}
                  register={register}
                />

                <Button
                  primary
                  type={'submit'}>
                  Enregistrer
                </Button>
              </section>

              <section>
                <h3>Note importante</h3>
                <Textarea
                  name={"note"}
                  placeholder={""}
                  onChange={(val) => setValue('note' , val )}
                  defaultValue={meet.note ? meet.note : ''}
                  register={register}
                />

                <Button
                  primary
                  type={'submit'}>
                  Enregistrer
                </Button>
              </section>

              <section>
                <Button
                  danger
                  outline
                  onClick={() => deleteMeet(meet._id)}
                  type={'button'}>
                  Supprimer le rendez-vous
                </Button>
              </section>

              </form>
 

            </div>
            <section className={styles.contact}>
              <h3>Rendez-vous</h3>
              {meet?.startDate &&
              <p>
                le {new Intl.DateTimeFormat('fr', options ).format(new Date(meet?.startDate))}
                <br/>
                de {format(new Date(meet?.startDate) , 'HH:mm')} à {format(new Date(meet?.endDate) , 'HH:mm')}
              </p>
              }
              {entity &&
                <>
                  <p className="user bold">avec {entity.name}</p>
                  <Link to={`/admin/cases/${entity.case}/${entity._id}`} ><HiUser size={20}/> voir le client</Link>
                </>
              }
            </section>
          </main>
        </>
      }
      <Modale
        isOpen={fileModaleIsActive}
        modaleToggle={setFileModaleIsActive}
        >
        {fileModaleIsActive && 
          <DocumentModale 
            setModaleIsActive={setFileModaleIsActive}
            meet={meet}
        />}
      </Modale>
    </div>
  )
}
