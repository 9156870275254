import React, { useState } from 'react';
import { NavLink , useLocation , useParams } from 'react-router-dom';
import { MdMenu , MdClose } from 'react-icons/md';
import { HiArrowLeft } from 'react-icons/hi';

import { SITE_URL } from '../../../../../actions/index';

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { getManageAction } from '../../../../../actions/usersActions';

import ActiveUser from '../../../../partials/ActiveUser/ActiveUser';

import logo from '../../../../../assets/images/logo-xs-white.svg';
import styles from './NavMember.module.scss';
import NavMobile from './NavMobile/NavMobile';
import SelectEntity from '../../../../partials/SelectEntity/SelectEntity';

export default function Nav({ logout }) {

  const location = useLocation();
  const { entityId } = useParams();

  const dispatch = useGlobalContext()[1];

  const [ mobileNavIsActive , setMobileNavIsActive ] = useState();

  function renderActivePage() {
    if (location.pathname === `/member/account/${entityId}`) {
      return "Informations";
    } else if (location.pathname === `/member/documents/${entityId}`) {
      return "Documents";
    } else if (location.pathname === `/member/subscription/${entityId}`) {
      return "Abonnement";
    } else {
      return "Outils";
    }
  }

  return (
    <nav className={styles['nav-member']}>
      <div>
        <div className={styles.logo}>
          <img src={logo} alt="logo"/>
        </div>

        <div className={styles.user}>
          <ActiveUser logout={logout} />
        </div>
  
        <div className={styles['select-entity']}>
          <SelectEntity />
        </div>

        <div className={`${styles.links}`}>
          <NavLink to={`/member/tools/${entityId}`} activeClassName={styles['link-is-active']}>
            <div className={styles.puce}></div><p>Outils</p>
          </NavLink>
          <NavLink to={`/member/account/${entityId}`} activeClassName={styles['link-is-active']}>
            <div className={styles.puce}></div><p>Informations</p>
          </NavLink>
          <NavLink to={`/member/documents/${entityId}`} activeClassName={styles['link-is-active']}>
            <div className={styles.puce}></div><p>Documents</p>
          </NavLink>
          <button onClick={() => getManageAction(dispatch, entityId)}>
            <div className={styles.puce}></div><p>Abonnement</p>
          </button>
        </div>

      </div>

      <div className={styles.back}>
        <a href={SITE_URL}>
          <HiArrowLeft size={20} />retour au site
        </a>
      </div>

      <div className={styles['mobile-toggle']}>
        {mobileNavIsActive ?
          <>
            <p className="bold">Menu</p>
            <button onClick={() => setMobileNavIsActive(!mobileNavIsActive)}>
              <MdClose size={26} color={'#FFFFFF'} />
            </button>
          </>
        :
          <>
            <p className="bold">{renderActivePage()}</p>
            <button onClick={() => setMobileNavIsActive(!mobileNavIsActive)}>
              <MdMenu size={26} color={'#FFFFFF'} />
            </button>
          </>
        }
      </div>
      <NavMobile
        closeNav={() => setMobileNavIsActive(false)}
        isActive={mobileNavIsActive}
        getManageAction={() => getManageAction(dispatch , entityId)}
        entityId={entityId}
        logout={logout}
        />
    </nav>
  )
}
