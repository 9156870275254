import React from 'react';
import { MdCloudDownload } from 'react-icons/md';
import { format } from 'date-fns';

import { API_URL } from '../../../../actions/index';

import { useGlobalContext } from '../../../../context/GlobalContext';

import UserInfo from '../partials/UserInfo/UserInfo'
import styles from './DocumentsPage.module.scss'
import DocumentRow from '../../../partials/DocumentRow/DocumentRow';

export default function DocumentsPage() {

  const context = useGlobalContext()[0];
  const quotes = context.cases?.entity?.quotes;
  const files = context.cases?.entity?.files;

  const token = localStorage.getItem('token').replace('JWT ', '');

  return (
    <div className="page-content member">
      <UserInfo />
      <h1>Mes documents</h1>
      <div className={styles.container}>
        {files?.map((f) => <DocumentRow
          key={f._id}
          file={f}
          styles={styles}
        />)}
        {token && quotes && quotes[0]?.missionLetter?._id && 
          <div className={`${styles['list-row']} ${styles['list-item']}`}>
              <a href={`${API_URL}/file/download/${quotes[0].missionLetter._id}?token=${token}`} target="_blank" rel="noreferrer"className={styles['col']}>
                <div className={styles.download}>
                  <MdCloudDownload size={20} />
                </div>
              <p className="bold"><span className="uppercase">{quotes[0].missionLetter.title}</span></p>
            </a>
            <div className={styles['col']}>
              <p><span className="uppercase">{format(new Date(quotes[0].missionLetter.createdAt) , 'dd/MM/yyyy HH:mm')}</span></p>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
