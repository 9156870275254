import React ,{ useCallback , useEffect  , useState } from 'react'

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { getMeetsListAction } from '../../../../../actions/meetsActions';
import { getUsersListAction } from '../../../../../actions/usersActions';

import Input from '../../../../partials/Form/Input/Input';
import SortButton from '../../../../partials/SortButton/SortButton';
import CustomSelect from '../../../../partials/Form/Select/CustomSelect';
import MeetRow from './MeetRow/MeetRow';

import styles from './MeetsListPage.module.scss';

export default function MeetsListPage() {

  const [ context , dispatch ] = useGlobalContext();

  const { meetsList } = context.meets ;
  const { usersList } = context.users ;

  const [ filtredList , setFiltredList ] = useState();
  const [ search , setSearch ] = useState('');
  const [ selectedAdmin , setSelectedAdmin ] = useState();
  const [ admins , setAdmins ] = useState();

  const initSortState = {
    name: true,
    company: true,
    date: true,
    user: true,
  }

  const [ sortByReverse , setSortByReverse ] = useState(initSortState);

  const getDatas = useCallback(() => {
    getMeetsListAction(dispatch);
    getUsersListAction(dispatch);
    dispatch('CLEAR_USER');
  },[dispatch]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    if (context.users.adminsList.length) {
      let list = context.users.adminsList.map(u => ({ value: u._id , label: `${u.profile.lastName} ${u.profile.firstName}`}));
      list.push({ value: 'all' , label: "Tous les conseillers"});
      setAdmins(list);
    }
  },[context.users.adminsList]);

  useEffect(() => {
    if (meetsList && usersList.length > 0) {
      setFiltredList(meetsList)
    }
  },[meetsList , usersList]);

  useEffect(() => {
    if (search) {
      let searchList = meetsList.filter(item => {
        if (item.client?.name.toLowerCase().includes(search.toLowerCase())) return true;
        return false;
      })
      setFiltredList(searchList)
    } else {
      setFiltredList(meetsList)
    }

  },[meetsList , search]);

  useEffect(() => {
    if (selectedAdmin && selectedAdmin !== 'all' ) {
      let statesList = meetsList.filter(item => {
        if (item.user?._id === selectedAdmin) return true;
        return false;
      })
      setFiltredList(statesList)
    } else {
      setFiltredList(meetsList)
    }

  },[meetsList , selectedAdmin]);

  function sortBy(type , isReverse ) {
    let list = filtredList;
    switch (type) {

      case 'name':
        list = list.sort((a , b ) => {
          if (a.client.name < b.client.name) return -1;
          if (a.client.name > b.client.name) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , name: isReverse })
        break;

      case 'date':
        list = list.sort((a , b ) => {
          if (new Date(a.createdAt) < new Date(b.createdAt)) return -1;
          if (new Date(a.createdAt) > new Date(b.createdAt)) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , date: isReverse })
        break;

      case 'user':
        list = list.sort((a , b ) => {
          if (a.user.profile.lastName < b.user.profile.lastName) return -1;
          if (a.user.profile.lastName > b.user.profile.lastName) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , user: isReverse })
        break;
      
      default:
        break;
    }
  }

  return (
    <div className="page-content">
      <div className={`${styles['header']}`}>
        <div className={`${styles['row']}`}>
          <div className={`${styles['col-3']}`}>
            <Input
              value={search}
              onChange={(val) => setSearch(val) }
              placehloder="Recherche"
            />
          </div>
          <div className={`${styles['col-3']}`}>
            <CustomSelect
              handleChange={(val) => setSelectedAdmin(val.value)}
              placeholder={"Conseiller"}
              options={admins}
              />
          </div>
        </div>
        <div className={`${styles['list-row']} ${styles['sort']}`}>
          <SortButton name={"Nom du client"} action={() => sortBy('name' , !sortByReverse.name )} state={sortByReverse.name} className={styles['col']}/>
          <SortButton name={"Date"} action={() => sortBy('date' , !sortByReverse.date )} state={sortByReverse.date} className={`${styles['col']} ${styles['state']}`}/>
          <SortButton name={"Conseiller"} action={() => sortBy('user' , !sortByReverse.user )} state={sortByReverse.user} className={`${styles['col']} ${styles['state']}`}/>
        </div>
      </div>
      <div className={styles['list']}>
        {filtredList?.map((meet , i ) => <MeetRow key={'meet' + i} styles={styles} meet={meet} />)}
      </div>
    </div>
  )
}
