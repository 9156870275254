export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              GET_LIST= 'GET_LIST',
              LOGIN = 'LOGIN',
              PUT_USER_AUTH = 'PUT_USER_AUTH',
              MESSAGE_AUTH = 'MESSAGE_AUTH',
              SET_TOAST = 'SET_TOAST',
              //USERS ACTIONS
              MESSAGE_USERS = 'MESSAGE_USERS',
              ERROR_USERS = 'ERROR_USERS',
              GET_USERS = 'GET_USERS',
              GET_USER = 'GET_USER',
              PUT_USER = 'PUT_USER',
              POST_USER = 'POST_USER',
              DELETE_USER = 'DELETE_USER',
              CLEAR_USER = 'CLEAR_USER',
              //CASES ACTIONS
              ERROR_CASES = 'ERROR_CASES',
              GET_CASES = 'GET_CASES',
              GET_CASE = 'GET_CASE',
              POST_CASE = 'POST_CASE',
              PUT_CASE = 'PUT_CASE',
              DELETE_CASE ='DELETE_CASE',
              GET_CASES_LIST = 'GET_CASES_LIST',
              GET_CASES_FILTERED = 'GET_CASES_FILTERED',
              SET_CASE_QUERY = 'SET_CASE_QUERY',
              SET_CASE_SCROLL = 'SET_CASE_SCROLL',
              //ENTITY ACTIONS
              GET_ENTITY = 'GET_ENTITY',
              PUT_ENTITY = 'PUT_ENTITY',
              POST_ENTITY = 'POST_ENTITY',
              //QUOTES ACTIONS
              ERROR_QUOTES = 'ERROR_QUOTES',
              GET_QUOTES = 'GET_QUOTES',
              GET_QUOTE = 'GET_QUOTE', 
              PUT_QUOTE = 'PUT_QUOTE',
              RESET_QUOTE = 'RESET_QUOTE',
              POST_QUOTE = 'POST_QUOTE',
              DELETE_QUOTE ='DELETE_QUOTE',
              LAUNCH_MISSION = 'LAUNCH_MISSION',
              GET_QUOTES_LIST = 'GET_QUOTES_LIST',
              GET_QUOTES_FILTERED = 'GET_QUOTES_FILTERED',
              SET_QUOTE_QUERY = 'SET_QUOTE_QUERY',
              SET_QUOTE_SCROLL = 'SET_QUOTE_SCROLL',
              //FILES ACTIONS
              POST_FILE = 'POST_FILE',
              DELETE_FILE = 'DELETE_FILE',
              ERROR_UPLOAD = 'ERROR_UPLOAD',
              //MEETS ACTIONS
              ERROR_MEETS = 'ERROR_MEETS',
              GET_MEETS = 'GET_MEETS',
              GET_MEET = 'GET_MEET',
              PUT_MEET = 'PUT_MEET',
              POST_MEET = 'POST_MEET',
              CLEAR_MEET = 'CLEAR_MEET',
              GET_CALENDAR = 'GET_CALENDAR',
              POST_CALENDAR = 'POST_CALENDAR';

