export  const quotesStates = [
  { 
      value: { name: 'valid' , color: "#5CC79A" } ,
      label: 'Validé'
  },
  { 
    value: { name: 'signed' , color: "#5CC79A" } ,
    label: 'Signé'
  },
  { 
    value: { name: 'signature' , color: "#FBA847" } ,
    label: 'Signature en cours'
  },
  { 
    value: { name: 'waiting' , color: "#FBA847" } ,
    label: 'En cours'
  },
  { 
    value: { name: 'refused' , color: "#D72700" } ,
    label: 'Refusé'
  },
];

export  const quotesOptions = [
  { 
      value: { name: 'valid' , color: "#5CC79A" } ,
      label: 'Validé'
  },
  { 
    value: { name: 'waiting' , color: "#FBA847" } ,
    label: 'En cours'
  },
  { 
    value: { name: 'refused' , color: "#D72700" } ,
    label: 'Refusé'
  },
];



export const labels = {
  subActivity : {
    furnished: "Meublée",
    unfurnished: "Non meublée",
    unknown: "innconue"
  },
  launchState: {
    launching: "Lancement d'activité",
    launched: "Activité déja lancée",
  },
  administrative: {
    electronic : "Caisse électronique",
    manual: "Caisse manuelle",
    invoice: "Factures",
    unknown: "Ne sais pas"
  },
  turnover : {
    "lessThan35000": "< 35 000 € par an",
    "between35000and100000": "Entre 35 et 100 000 € / an",
    "moreThan100000" : "Plus de 100 000 € / an",
    "other": "préciser CA si plus de 100 K"
  },
  type: {
    company: "Société",
    individual: "Entreprise individuelle",
    unknown: "Je ne sais pas"
  },
  tvaPeriodicity:  { 
    annual: "Annuelle", 
    quarterly: "Trimestrielle",
    monthly: "Mensuelle",
    unknown: "innconue"
  },
  isTaxed: {
    unknown: "innconu",
    yes: "Soumise à l’impot sur société",
    no: "Non soumise à l’impot sur société",
  }
}

export const months = [
  "jan",
  "fév",
  "mar",
  "avr",
  "mai",
  "juin",
  "juil",
  "aôut",
  "sept",
  "oct",
  "nov",
  "déc"
];

export const daysWords = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

export const days = [
  "jan",
  "fév",
  "mar",
  "avr",
  "mai",
  "juin",
  "juil",
  "aôut",
  "sept",
  "oct",
  "nov",
  "déc"
];

export const questions = [
  {
    question: "Votre situation...",
    key: "launchState",
    type: "list",
    activities : ['location' , 'liberal' , 'freelance' , 'artisan' ],
    options: [
      { label: "Je me lance !", value: "launching" },
      { label: "Mon activité est lancée", value: "launched"},
    ]
  },
  {
    question: "Souhaitez-vous un accompagnement à la création de votre entreprise ?",
    key: "needAccompaniment",
    activities : ['location' , 'liberal' , 'freelance' , 'artisan' ],
    type: "list",
    options: [
      { label: "Oui", value: true },
      { label: "Non", value: false },
    ]
  },
  {
    question: "Quelques petites précisions...",
    key: "subActivity",
    type: "list",
    activities : ['location'],
    options: [
      { label: "Meublée", value: "furnished" },
      { label: "Non meublée", value: "unfurnished"},
      { label: "Je ne sais pas", value: "unknown"},
    ]
  },
  {
    question: "Vous exercez en...",
    key: "type",
    type: "list",
    activities : ['location' , 'liberal' , 'freelance' , 'artisan' ],
    options: [
      { label: "Société", value: "company" },
      { label: "Entreprise individuelle", value: "individual"},
      { label: "Je ne sais pas", value: "unknown"},
    ]
  },
  {
    question: "Votre organisation administrative",
    key: "administrative",
    type: "list",
    activities : ['artisan'],
    options: [
      { label: "Caisse électronique", value: "electronic" },
      { label: "Caisse manuelle", value: "manual"},
      { label: "Factures", value: "invoice" },
      { label: "Ne sais pas", value: "unknown" },
    ]
  },
  {
    question: "Chiffre d'affaires/recettes",
    key: "turnover",
    type: "list",
    activities : ['liberal' , 'freelance' , 'artisan' , 'entrepreneur' ],
    options: [
      { label: "< 35 000 € par an", value: "lessThan35000" },
      { label: "Entre 35 et 100 000 € / an", value: "between35000and100000"},
      { label: "préciser CA si plus de 100 K", value: "other" },
    ]
  },
  {
    question: "Si choix SOCIETE : Elle est soumise à Impôt sur les sociétés ?",
    key: "isTaxed",
    type: "list",
    activities : ['location'],
    options: [
      { label: "Oui", value: "yes" },
      { label: "Non", value: "no"},
      { label: "Je ne sais pas", value: "unknown"},
    ]
  },
  {
    question: "Préciser votre chiffre d'affaires",
    key: "turnoverValue",
    type: "number",
    activities : ['other' ],
  },
  {
    question: "Votre nombre de biens",
    key: "propertyNumber",
    type: "number",
    activities : ['location'],
  },
  {
    question: "Vous appliquez la TVA ?",
    key: "tva",
    type: "list",
    activities : ['location' , 'liberal' , 'freelance' , 'artisan' , 'entrepreneur'],
    options: [
      { label: "Oui", value: "yes" },
      { label: "Non", value: "no"},
      { label: "Je ne sais pas", value: "unknown"},
    ]
  },
  {
    question: "Quelle est sa périodicité ?",
    key: "tvaPeriodicity",
    type: "list",
    activities : ['location' , 'liberal' , 'freelance' , 'artisan' , 'entrepreneur' ],
    options: [
      { label: "Annuelle", value: "annual" },
      { label: "Trimestrielle", value: "quarterly"},
      { label: "Mensuelle", value: "monthly"},
      { label: "Je ne sais pas", value: "unknown"},
    ]
  },
  {
    question: "Vous réfélchissez à changer de statuts ?",
    key: "statusSwitch",
    type: "list",
    activities : [ 'entrepreneur' ],
    options: [
      { label: "Oui", value: "yes" },
      { label: "Non", value: "no"},
      { label: "Je ne sais pas", value: "unknown"},
    ]
  },
  {
    question: "Des salariés ?",
    key: "employee",
    type: "number",
    activities : ['liberal' , 'freelance' , 'artisan' , 'entrepreneur' ],
  },
  {
    question: "Prix HT par salarié",
    key: "employeePrice",
    type: "number",
    activities : ['liberal' , 'freelance' , 'artisan' , 'entrepreneur' ],
  },
 
];

export const activitiesOptions = [
  { label: "Activité de location immobilière", value: "location" },
  { label: "Profession libérale", value: "liberal"},
  { label: "Prestataire de services , Freelance, Consultant", value: "freelance"},
  { label: "Artisan , Commerçant", value: "artisan"},
  { label: "Micro entrepreneur", value: "entrepreneur"},
  { label: "Autres", value: "other"},
]

export function getActivity(val) {
  return activitiesOptions.find((opt) => opt.value === val)?.label;
}