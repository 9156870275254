import React from 'react';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import MeetInfo from '../MeetInfo/MeetInfo';
import QuoteInfo from '../QuoteInfo/QuoteInfo';
import styles from './UserInfo.module.scss';

export default function UserInfo() {

  const context = useGlobalContext()[0];

  const quotes = context.cases?.entity?.quotes;
  const appointments = context.cases?.entity?.appointments;

  return (
    <div className={styles.container}>
      {quotes?.map((q) => <QuoteInfo key={q._id} quote={q} />)}
      {appointments?.map((m) => <MeetInfo key={m._id} appointment={m} />)}
    </div>
  )
}
