import React from 'react';
import { NavLink } from 'react-router-dom';

import { FaRegBell } from 'react-icons/fa';

import ActiveUser from '../../../../../partials/ActiveUser/ActiveUser';

import styles from './NavMobile.module.scss';

export default function NavMobile({ logout , isActive , closeNav }) {
  return (
    <div className={`${styles.nav} ${isActive && styles["is-active"]}`}>

      <div className={styles.user}>
        <ActiveUser logout={logout}/>
        <div className={styles.notif}>
          <FaRegBell size={21} color={'#C6CBD8'} />
          <p className="bold">Notifications</p>
        </div>
      </div>

      <div className={`${styles.links}`} onClick={() => closeNav()}>
        <NavLink to={`/admin/quote`} activeClassName={styles['link-is-active']}>
          <div className={styles.link}>
            <div className={styles.puce}></div>
            <p>Liste des devis</p>
          </div>
        </NavLink>
        <NavLink exact to={'/admin/user'} activeClassName={styles['link-is-active']}>
          <div className={styles.link}>
            <div className={styles.puce}></div>
            <p>Liste des utilisateurs</p>
          </div>
        </NavLink>
        <NavLink to={`/admin/meet`} activeClassName={styles['link-is-active']}>
          <div className={styles.link}>
            <div className={styles.puce}></div>
            <p>Rendez-vous</p>
          </div>
        </NavLink>
      </div>

    </div>
  )
}
