import React ,{ useCallback , useEffect  , useState , useRef } from 'react';
import { useForm , Controller } from 'react-hook-form';
import { GoPlus } from 'react-icons/go';

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { exportCasesAction, getCasesListsAction, postCasesFilteredAction } from '../../../../../actions/casesActions';

import Input from '../../../../partials/Form/Input/Input';
import SortButton from '../../../../partials/SortButton/SortButton';
import CaseRow from './CaseRow/CaseRow';
import Modale from '../../../../partials/Modale/Modale';
import Button from '../../../../partials/Button/Button';

import styles from './CasesListPage.module.scss';
import CaseModale from '../../../../partials/CaseModale/CaseModale';
import { SET_CASE_QUERY, SET_CASE_SCROLL } from '../../../../../actions/types';
import CustomSelect from '../../../../partials/Form/Select/CustomSelect';


export default function CasesListPage() {

  const [ context , dispatch ] = useGlobalContext();
  const { casesList , query , count , lists, scrollY } = context.cases;

  const { register, watch , setValue , control } = useForm();
  const listRef = useRef(null);
  const scrollRef = useRef(null);

  const [ isCaseModale , setIsCaseModale ] = useState();
  const [ filtredList , setFiltredList ] = useState();
  const [ isLoading, setIsLoading] = useState(true);
  const [ search , setSearch ] = useState('');

  const users = watch('users');
  const entities = watch('entities');

  const initSortState = {
    name: true,
    entities: true,
  }

  const [ sortByReverse , setSortByReverse ] = useState(initSortState);

  const getLists = useCallback(() => {
    getCasesListsAction(dispatch);
  },[dispatch]);

  const getDatasFiltered = useCallback(() => {
    setIsLoading(true);
    postCasesFilteredAction(dispatch , query);
  },[dispatch, query]);

  const setFilters = useCallback((updatedQuery) => {
    dispatch({
      type:SET_CASE_QUERY,
      payload: updatedQuery,
    }) 
  },[dispatch]);

  function sortBy(key , isReverse ) {
    switch (key) {
      case 'name':
        setSortByReverse({...initSortState , name: isReverse })
        break;
      case 'entities':
        setSortByReverse({...initSortState , entities: isReverse })
        break;
      default:
        break;
    }
    dispatch({
      type:SET_CASE_QUERY,
      payload: {
        ...query,
        page: 1,
        orderBy: { 
          key,
          order: isReverse ? "asc" : "desc",
        }
      }
    }) 
  }

  function handleScroll(e) {
    dispatch({
      type: SET_CASE_SCROLL,
      payload: e.target.scrollTop,
    });
    if (!isLoading
        && count > casesList?.length
        && scrollRef?.current.getBoundingClientRect().bottom
        === listRef?.current.getBoundingClientRect().bottom
    ) {
      dispatch({
        type: SET_CASE_QUERY,
        payload: {
          ...query,
          page: query.page + 1,
        },
      });
    }
  }

  useEffect(() => {
    getLists();
  }, [getLists]);

  useEffect(() => {
    register("users");
    register('entities');
  }, [register]);

  useEffect(() => {
    getDatasFiltered();
  }, [getDatasFiltered, query.page , query.users, query.entities]);

  useEffect(() => {
    let timer;
    if (search?.length > 3) {  
      setFilters({
        ...query,
        page: 1,
        search,
      });
    } else {
      setFilters({
        ...query,
        page: 1,
        search: '',
      });
    }
    return () => clearTimeout(timer);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setFilters({
      ...query,
      page: 1,
      users: users,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[users]);

  useEffect(() => {
    setFilters({
      ...query,
      page: 1,
      entities: entities,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[entities])

  useEffect(() => {
    if (casesList) {
      setFiltredList(casesList)
    }
  },[casesList]);

  useEffect(() => {
    if (query.page === 1) {
      listRef.current.scrollTop = 0;
    }
  }, [query.page]);

  useEffect(() => {
    if (scrollY) {
      listRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
  }, [casesList, scrollY]);

  return (
    <>
    <div className="page-content">
      <div className={`${styles['header']}`}>
        <div className={styles['row']}>
          <div className={styles['col-4']}>
            <Input
              value={search}
              onChange={(val) => setSearch(val) }
              placehloder="Recherche"
            />
          </div>
          <div className={styles['col-4']}>
            {lists?.users &&
              <Controller
                name="users"
                rules={{ }}
                control={control}
                render={(props) =>
                  <CustomSelect
                    label={""}
                    defaultValue={lists?.users.filter((o) => query?.users?.find((u) => u === o.value))}
                    isMulti
                    handleChange={(array) => setValue('users' , array?.map((opt) => opt.value) )}
                    options={lists.users}
                    withErrors={false}
                    />
                }
                defaultValue={null}
              />
            }
          </div>
          <div className={styles['col-4']}>
            {lists?.entities &&
              <Controller
                name="entities"
                rules={{ }}
                control={control}
                render={(props) =>
                  <CustomSelect
                    label={""}
                    defaultValue={lists?.entities.filter((o) => query?.entities?.find((u) => u === o.value))}
                    isMulti
                    handleChange={(array) => setValue('entities' , array?.map((opt) => opt.value) )}
                    options={lists.entities}
                    withErrors={false}
                    />
                }
                defaultValue={null}
              />
            }
          </div>
          <div className={`${styles['add']}`}>
            <Button
              type="button"
              primary
              onClick={() => exportCasesAction(dispatch)}
              >
              EXPORTER
            </Button>
            <Button
              type="button"
              primary
              onClick={() => setIsCaseModale({})}
              >
              <GoPlus />NOUVEAU <span>DOSSIER</span>
            </Button>
          </div>
        </div>
        <div className={`${styles['list-row']} ${styles['sort']}`}>
          <SortButton name={"Nom du dossier"} action={() => sortBy('name' , !sortByReverse.name )} state={sortByReverse.name} className={styles['col']}/>
          <SortButton name={"Entités"} action={() => sortBy('entities' , !sortByReverse.entities )} state={sortByReverse.entities} className={styles['col']}/>
        </div>
      </div>
      <div
        ref={listRef}
        onScroll={(e) => handleScroll(e)}
        className={styles.list}
      >
        <div
          className={styles.content}
          ref={scrollRef}>
          {filtredList?.length > 0 && filtredList?.map((c) => <CaseRow
              key={c._id}
              styles={styles}
              data={c}
            />
          )}
        </div>
      </div>
    </div>
    <Modale
      isOpen={isCaseModale ? true : false}
      modaleToggle={setIsCaseModale}
      >
      {isCaseModale
        && <CaseModale
          data={isCaseModale}
          setModaleIsActive={setIsCaseModale}
        />
      }
    </Modale>
  </>
  )
}
