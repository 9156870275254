import React from 'react';
import { NavLink } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import { SITE_URL } from '../../../../../../actions/index';

import ActiveUser from '../../../../../partials/ActiveUser/ActiveUser';

import styles from './NavMobile.module.scss';
import SelectEntity from '../../../../../partials/SelectEntity/SelectEntity';

export default function NavMobile({ logout , isActive , closeNav , entityId , getManageAction }) {
  return (
    <div className={`${styles.nav} ${isActive && styles["is-active"]}`}>
      <div>

        <div className={styles.user}>
          <ActiveUser logout={logout}/>
        </div>

        <SelectEntity />

        <div className={`${styles.links}`} onClick={() => closeNav()}>
          <NavLink to={`/member/tools/${entityId}`} activeClassName={styles['link-is-active']}>
            <div className={styles.link}>
              <div className={styles.puce}></div>
              <p>Outils</p>
            </div>
          </NavLink>
          <NavLink exact to={`/member/account/${entityId}`} activeClassName={styles['link-is-active']}>
            <div className={styles.link}>
              <div className={styles.puce}></div>
              <p>Informations</p>
            </div>
          </NavLink>
          <NavLink to={`/member/documents/${entityId}`}  activeClassName={styles['link-is-active']}>
            <div className={styles.link}>
              <div className={styles.puce}></div>
              <p>Documents</p>
            </div>
          </NavLink>
          <button onClick={() => getManageAction()}>
            <div className={styles.link}>
              <div className={styles.puce}></div><p>Abonnement</p>
            </div>
          </button>
          {/* <NavLink to={'/member/subscription'} activeClassName={styles['link-is-active']}>
            <div className={styles.link}>
              <div className={styles.puce}></div>
              <p>Abonnement</p>
            </div>
          </NavLink> */}

        </div>

      </div>

      <div className={styles.back}>
        <a href={SITE_URL}>
          <HiArrowLeft size={20} />retour au site
        </a>
      </div>

    </div>
  )
}
