import React from 'react';

export default function UserRow({ styles , user , handleClick }) {
  return (
    <div
      onClick={() => handleClick(user)}
      className={`${styles['list-row']} ${styles['list-item']}`}
    >
      <div className={styles['col']}>
        <p className="bold"><span className="uppercase">{user.profile.lastName}</span> {user.profile.firstName}</p>
      </div>
      <div className={styles['cases']}>
        {user?.cases?.map((c) => <p key={c.name}>{c.name}</p>)}
      </div>
    </div>
  );
}
