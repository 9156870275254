import React from 'react';
import { Controller, useForm } from "react-hook-form";

import { useGlobalContext } from '../../../context/GlobalContext';

import Input from '../Form/Input/Input';
import Button from '../Button/Button';

import styles from './UserModale.module.scss';
import CustomSelect from '../Form/Select/CustomSelect';
import { deleteUserAction, postUserAction, putUserAction } from '../../../actions/usersActions';

export default function UserModale({  user , setModaleIsActive, isCase }) {

  const dispatch = useGlobalContext()[1];
  const genderOptions = [
    {
      value: 'male',
      label: 'Masculin',
    },
    {
      value: 'female',
      label: 'Féminin',
    }
  ];

  const {
    handleSubmit,
    register,
    errors,
    setValue,
    control,
    watch ,
  
  } = useForm({
    defaultValues: user,
  });

  function submitForm(values) {
    if (user._id) {
      putUserAction(dispatch, user._id , values);
    } else {
      postUserAction(dispatch, values);
    }
    if (Object.entries(errors).length === 0) {
      setModaleIsActive();
    }
  };

  function deleteUser(id) {
    if (window.confirm("Souhaitez-vous supprimer l'utilisateur ?")) {
      deleteUserAction(dispatch, id);
      setModaleIsActive();
    }
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>{user._id ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'}</h2>
        <form onSubmit={handleSubmit(submitForm)}>
          <Input
            label={"Nom"}
            name={"profile.lastName"}
            defaultValue={user?.profile?.lastName}
            register={register({
              required: "Compléter le nom",
            })}
            withErrors={true}
            error={errors?.profile?.lastName?.message}
          />
          <Input
            label={"Prénom"}
            name={"profile.firstName"}
            defaultValue={user?.profile?.firstName}
            register={register({
              required: "Compléter le prénom",
            })}
            withErrors={true}
            error={errors?.profile?.firstName?.message}
          />
          <Input
            label={"Tel."}
            name={"profile.tel"}
            defaultValue={user?.profile?.tel}
            register={register({
              required: "Compléter le numéro de téléphone",
            })}
            withErrors={true}
            error={errors?.profile?.tel?.message}
          />
          <Input
            label={"Mail"}
            name={"email"}
            defaultValue={user?.email}
            register={register({
              required: "Compléter votre email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Adresse mail invalide"
              }
            })}
            withErrors={true}
            error={errors?.email?.message}
          />

          <Controller
            name='profile.gender'
            rules={{ required: "le genre est requis" }}
            control={control}
            defaultValue={genderOptions.find((opt) => opt.value === watch('profile.gender') || null)}
            render={() =>
              <CustomSelect
                label={"Genre"}
                defaultValue={genderOptions.find((opt) => opt.value === watch('profile.gender'))}
                handleChange={(opt) => setValue('profile.gender', opt.value)}
                options={genderOptions}
                withErrors={true}
                error={errors?.profile?.gender?.message}
                />
            }
          />

          <div className={styles.actions}>
            <Button
              type={"submit"}
              primary
              >
              Valider
            </Button>
            {user._id && !isCase
              && <Button
                danger
                onClick={() => deleteUser(user._id)}
                >
                Supprimer l'utilisateur
              </Button>
            }
          </div>
        </form>
    </div>
  )
}
