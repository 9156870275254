import React from 'react';
import { useForm } from "react-hook-form";

import { putUserAuthAction } from '../../../../actions/authActions';
import { useGlobalContext } from '../../../../context/GlobalContext';
import Input from '../../../partials/Form/Input/Input';

import styles from './AccountPage.module.scss';

export default function AccoutPage() {

  const [ context , dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors } = useForm();

  const { userAuth } = context.auth

  const submitForm = (values)  => {
    putUserAuthAction(dispatch , values)
  };

  return (
    <div className={`page-content`}>
      <div className={styles.account}>
      {userAuth && userAuth.email &&
        <form onSubmit={handleSubmit(submitForm)}>
          <h1 className="bold">Mes informations</h1>
          <Input
            label={"Nom"}
            name={"lastName"}
            defaultValue={userAuth.profile.lastName}
            register={register({
              required: "Compléter votre nom",
            })}
            withErrors={true}
            error={errors.lastName && errors.lastName.message}
            />
          <Input
            label={"Prénom"}
            name={"firstName"}
            defaultValue={userAuth.profile.firstName}
            register={register({
              required: "Compléter votre prénom",
            })}
            withErrors={true}
            error={errors.firstName && errors.firstName.message}
            />
          <Input
            label={"Mail"}
            name={"email"}
            defaultValue={userAuth.email}
            register={register({
              required: "Compléter votre email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Adresse mail invalide"
              }
            })}
            withErrors={true}
            error={errors.email && errors.email.message}
            />

          <button
            type="submit"
            className="btn primary">
            Valider
          </button>

        </form>
      }
      </div>
    </div>
  )
}
