import React, { useState } from 'react';
import { NavLink , useLocation } from 'react-router-dom';
// import { FaRegBell } from 'react-icons/fa';
import { MdMenu , MdClose } from 'react-icons/md';
 
import ActiveUser from '../../../../partials/ActiveUser/ActiveUser';

import logo from '../../../../../assets/images/logo-xs-white.svg';
import styles from './Nav.module.scss';
import NavMobile from './NavMobile/NavMobile';

export default function Nav({ logout }) {

  const location = useLocation();

  const [ mobileNavIsActive , setMobileNavIsActive ] = useState();

  function renderActivePage() {
    let activePage = location.pathname.split('/')[2];
    if (activePage === "quote") {
      return "Devis";
    } else if (activePage === "meet") {
      return "Rendez-vous";
    } else if (activePage === "cases") {
      return "Dossiers";
    } else {
      return "Utilisateurs";
    }
  }

  return (
    <nav className={styles.nav}>
      <div className={styles.logo}>
        <img src={logo} alt="logo"/>
      </div>

      <div className={`${styles.links}`}>
        <NavLink to={`/admin/quote`} activeClassName={styles['link-is-active']}>
          <p>Liste des devis</p>
        </NavLink>
        <NavLink to={'/admin/cases'} activeClassName={styles['link-is-active']}>
          <p>Liste des dossiers</p>
        </NavLink>
        <NavLink to={'/admin/user'} activeClassName={styles['link-is-active']}>
          <p>Liste des utilisateurs</p>
        </NavLink>
        <NavLink to={`/admin/meet`} activeClassName={styles['link-is-active']}>
          <p>Rendez-vous</p>
        </NavLink>
      </div>

      <div className={styles.user}>
        {/* <FaRegBell size={21} color={'#C6CBD8'} /> */}
        <ActiveUser logout={logout} />
      </div>

      <div className={styles['mobile-toggle']}>
        {mobileNavIsActive ?
          <>
            <p className="bold">Menu</p>
            <button onClick={() => setMobileNavIsActive(!mobileNavIsActive)}>
              <MdClose size={26} color={'#FFFFFF'} />
            </button>
          </>
        :
          <>
            <p className="bold">{renderActivePage()}</p>
            <button onClick={() => setMobileNavIsActive(!mobileNavIsActive)}>
              <MdMenu size={26} color={'#FFFFFF'} />
            </button>
          </>
        }
      </div>
      <NavMobile
        path={'/admin'}
        closeNav={() => setMobileNavIsActive(false)}
        isActive={mobileNavIsActive}
        logout={logout}
        />
    </nav>
  )
}
