import {
  ERROR_CASES,
  GET_CASE,
  GET_CASES,
  PUT_CASE,
  DELETE_CASE,
  POST_CASE,
  GET_ENTITY,
  PUT_ENTITY,
  POST_ENTITY,
  SET_CASE_QUERY,
  GET_CASES_FILTERED,
  SET_CASE_SCROLL,
  GET_CASES_LIST,
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  casesList: [],
  case: null,
  entity: null,
  count: 0,
  scrollY: 0,
  lists: null,
  query: {
    search:"",
    users:[],
    entities:[],
    page:1,
    orderBy: {
      key: "name",
      order: "desc"
    },
    number:20,
  }
};

export default function casesReducer(state = initialState, action) {
  switch (action.type) {
    case POST_CASE:
      let createdList = [...state.casesList];
      createdList.unshift(action.payload);
      return {...state ,
        casesList : createdList,
      }

    case PUT_CASE:
      const updatedList = state.casesList;
      const index = state.casesList.findIndex((c) => c._id === action.payload._id);
      if (index !== -1) {
        updatedList[index] = action.payload;
      }
      return {...state ,
        casesList : updatedList,
        case : action.payload,
      };

    case SET_CASE_QUERY:
      return {...state , query: action.payload };

    case GET_CASE:
      return {...state , case: action.payload };

    case DELETE_CASE:
      const deleteList = state.casesList.filter((c) => c._id !== action.payload);
      return {...state , casesList: deleteList };

    case GET_CASES:
      return {...state , casesList: action.payload, entity: null };

    case GET_CASES_FILTERED:
      let newCases = state.casesList;
      const { page } = state.query;
      if (page === 1) {
        newCases = [...action.payload.cases]
      } else {
        newCases = [...newCases,...action.payload.cases]
      }
      return {...state , casesList: newCases, count: action.payload.count , entity: null };

    case PUT_ENTITY:
      return {...state , entity: action.payload };
    
    case POST_ENTITY:
      const caseUpdated = {...state.case}
      caseUpdated.entities = [...caseUpdated.entities, action.payload]
      return {...state , entity: action.payload , case: caseUpdated };

    case GET_ENTITY:
      return {...state , entity: action.payload };

    case SET_CASE_SCROLL:
      return {...state, scrollY: action.payload };

    case GET_CASES_LIST:
      return {...state , lists: action.payload };

    case ERROR_CASES:
      return {...state , error: action.payload};
      
    default:
      return {...state};
  }
}