import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'

import { GlobalProvider } from './context/GlobalContext';
import authReducer from './reducers/authReducer';
import usersReducer from './reducers/usersReducer';
import quotesReducer from './reducers/quotesReducer';

import GlobalRoutes from './components/GlobalRoutes';
import filesReducer from './reducers/filesReducer';
import meetsReducer from './reducers/meetsReducer';
import casesReducer from './reducers/casesReducer';

function App() {

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    auth: authReducer,
    users: usersReducer,
    cases: casesReducer,
    quotes: quotesReducer,
    files: filesReducer,
    meets: meetsReducer
  });

  const initialState = reducers( {} , { type: '__INIT__' });

  return (
    <GlobalProvider
      reducers={reducers}
      initialState={initialState}
      >
      <div className="app-container">
        <Router>
          <GlobalRoutes />
        </Router>
      </div>
    </GlobalProvider>
  );
}

export default App;