import React , { useEffect , useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useGlobalContext } from '../../../context/GlobalContext';
import { getUserAuthAction } from '../../../actions/authActions';
import { ERROR_AUTH } from '../../../actions/types';

export default function SwitchIndex() {

  const [ context , dispatch ] = useGlobalContext();

  const history = useHistory();
  const { userAuth } = context.auth;

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  const clearError = useCallback(() => {
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
  },[dispatch]);

  useEffect(() => {
    getUser();
    return () => {
      clearError();
    }
  }, [getUser , clearError])

  useEffect(() => {
    if (userAuth) {
      if (userAuth.role === "Admin") {
        history.push('/admin/cases');
      }
      if (userAuth.role === "Member" && userAuth?.favoriteEntity) {
        history.push(`/member/tools/${userAuth?.favoriteEntity}`);
      }
    }
  },[userAuth , history]);

  return (
    <div className="page-container switch">
    </div>
  )
}
