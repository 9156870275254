import React, { useEffect , useCallback } from 'react';
import { Controller, useForm } from "react-hook-form";

import { useGlobalContext } from '../../../context/GlobalContext';

import Input from '../Form/Input/Input';
import Button from '../Button/Button';

import styles from './CaseModale.module.scss';
import CustomSelect from '../Form/Select/CustomSelect';
import { postCaseAction, putCaseAction } from '../../../actions/casesActions';
import { getUsersListAction } from '../../../actions/usersActions';

export default function CaseModale({  data , setModaleIsActive }) {

  const [ context, dispatch ] = useGlobalContext();
  const { usersList } = context.users;

  const usersOptions = usersList.map((u) => ({ value: {...u , toString: () => u._id } , label: `${u.profile.lastName} ${u.profile.firstName}`}));
  const originsOptions = ['SWIVEL','Réseau sociaux','Bouche à oreille','Autre'].map((v) => ({ value: v, label: v}));

  const {
    handleSubmit,
    register,
    errors,
    setValue,
    control,
    watch ,
  
  } = useForm({
    defaultValues: data,
  });

  const getDatas = useCallback(() => {
    getUsersListAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  function submitForm(values) {
    const val = {...values, users: values.users.map((u) => u._id)}
    if (data._id) {
      putCaseAction(dispatch, data._id , val);
    } else {
      postCaseAction(dispatch, val);
    }
    if (Object.entries(errors).length === 0) {
      setModaleIsActive();
    }
  };

  const selectedUser = usersOptions?.filter(
    (opt) => opt?.value?._id === watch('users')?.find((u) => u?._id === opt?.value?._id )?._id 
      || opt?.value?._id === watch('users')?.find((u) => u === opt?.value?._id )
      ? opt : null
  )

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>{data._id ? 'Edition du dossier' : 'Ajouter un dossier'}</h2>
        <form onSubmit={handleSubmit(submitForm)}>
          <Input
            label={"Nom du dossier"}
            name={"name"}
            defaultValue={data?.name?.lastName}
            register={register({
              required: "Compléter le nom",
            })}
            withErrors={true}
            error={errors?.name?.message}
          />
          <Controller
            name='origin'
            rules={{ required: "l'origine est requise" }}
            control={control}
            defaultValue={originsOptions.find((opt) => opt.value === watch('origin') || null)}
            render={() =>
              <CustomSelect
                label={"Provenance"}
                defaultValue={originsOptions.find((opt) => opt.value === watch('origin'))}
                handleChange={(opt) => setValue('origin', opt.value)}
                options={originsOptions}
                withErrors={true}
                error={errors?.origin?.message}
                />
            }
          />

          {watch('origin') === 'Autre' &&
            <Input
              label={"Autre provenance"}
              defaultValue={data?.customOrigin}
              register={register({
                required: "Compléter le nom",
              })}
              name={"customOrigin"}
              withErrors={true}
            />
          }

          {selectedUser && usersOptions.length > 0
            && <Controller
              name='users'
              control={control}
              defaultValue={selectedUser}
              render={() =>
                <CustomSelect
                  label={"Utilisateurs"}
                  defaultValue={selectedUser}
                  isMulti
                  isSearchable={true}
                  handleChange={(array) => setValue('users', array?.map((u) => u.value) || [])}
                  options={usersOptions}
                  withErrors={true}
                  />
              }
            />
          }

          <div className={styles.actions}>
            <Button
              type={"submit"}
              primary
              >
              Valider
            </Button>
          </div>
        </form>
    </div>
  )
}
