import {
  ERROR_MEETS,
  GET_MEETS,
  GET_MEET,
  PUT_MEET,
  CLEAR_MEET,
  POST_CALENDAR,
  GET_CALENDAR
  // DELETE_MEET,
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  meetsList: [],
  meet: null,
  calendar: null,
  meetDate: null,
};

export default function meetsReducer(state = initialState, action) {
  switch (action.type) {

    case POST_CALENDAR:
      return {...state , meetDate: action.payload.date}

    case GET_CALENDAR:
      return {...state , calendar: action.payload}

    case PUT_MEET:
      let updatedList = state.meetsList.filter(meet => meet._id !== action.payload.appointment._id);
      updatedList.push(action.payload.appointment)
      return {...state ,
        meetsList : updatedList,
        meet : action.payload.appointment,
        message: action.payload.message
      };

    case CLEAR_MEET:
      return {...state , meet: null };

    case GET_MEET:
      return {...state , meet: action.payload.appointment };

    case GET_MEETS:
      return {...state , meetsList: action.payload};

    case ERROR_MEETS:
      return {...state , error: action.payload};
      
    default:
      return {...state};
  }
}