import React , { useEffect , useState , useCallback } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FaEye , FaEyeSlash } from "react-icons/fa";
import { HiArrowLeft } from "react-icons/hi";
import { SITE_URL } from '../../../actions/index';

import { useGlobalContext } from '../../../context/GlobalContext';
import { loginAction , forgotPasswordAction , getUserAuthAction } from '../../../actions/authActions';
import logo from '../../../assets/images/logo-xl-blue.svg';

import './Auth.scss';
import { ERROR_AUTH } from '../../../actions/types';

export default function Login() {

  const { handleSubmit, register, errors } = useForm();
  const history = useHistory();

  const [ context, dispatch ] = useGlobalContext();
  const { userAuth, error , isAuth } = context.auth;

  const [ displayLogin , setDisplayLogin ] = useState(true);
  const [ errorForgot , setErrorForgot ] = useState();
  const [ showPassword , setShowPassword ] = useState(false);

  const clearError = useCallback(() => {
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
  },[dispatch]);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
    clearError();
  },[dispatch, clearError]);

  useEffect(() => {
    clearError();
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (error && error.status === 422) {
      setErrorForgot("Votre demande n'a pas pu être traitée telle qu'elle a été saisie. Veuillez réessayer.");
    }
  }, [error]);

  useEffect(() => {
    if(isAuth) {
      getUser();
    }
    clearError();
  // eslint-disable-next-line 
  }, [isAuth]);

  useEffect(() => {
    if (userAuth) {
      if (userAuth.role === "Admin") {
        history.push('/admin/cases');
      }
      if (userAuth.role === "Member" && userAuth?.favoriteEntity) {
        history.push(`/member/tools/${userAuth?.favoriteEntity}`);
      }
    } else {
      clearError();
    }
    // eslint-disable-next-line 
  },[userAuth , history]);


  const backFromReset = () => {
    setDisplayLogin(true)
    const obj = {}
    obj.message = null
    dispatch({
      type: 'FORGOT_PASSWORD',
      payload: obj,
    });
  }


  const submitLogin = (values)  => {
    loginAction( dispatch , values);
    clearError();
  };

  const submitReset = (values)  => {
    forgotPasswordAction( dispatch , values);
    clearError();
  };


  function renderLoginForm() {
    return (
      <form className="form-login" onSubmit={handleSubmit(submitLogin)}>

        <label htmlFor="email">Adresse mail</label>
        <input
          name="email"
          ref={register({
            required: "Compléter votre email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Adresse mail invalide"
            }
          })}
        />

        <p className="text-error">{errors.email && errors.email.message}</p>

        <label htmlFor="password">Mot de passe</label>
        <input
          name="password"
          type={showPassword? "text" : "password"}
          ref={register({
            required: "Compléter votre mot de passe",
          })}
        />
        <p className="text-error">{errors.password && errors.password.message}</p>
        <div
          className="eye"
          onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <FaEye size={20} color={"#C6CBD8"} /> : <FaEyeSlash size={20} color={"#C6CBD8"} /> }
        </div>
        
        <div className="btn-container">
          <button className="btn primary" type="submit">Connexion</button>
          <button onClick={() => setDisplayLogin(false)} className="btn-reset">J’ai oublié mon mot de passe</button>
        </div>


        <p className="text-error text-center">{context.auth.error && context.auth.error === 401 && <span> Adresse e-mail ou mot de passe invalide.</span>}</p>

        <div className="back">
          <a href={SITE_URL}>
            <HiArrowLeft size={24} />
            <p className="bold">Retour au site</p>
          </a>
        </div>
    
      </form>
    )
  }

  function renderResetForm() {
    if (context.auth && context.auth.message && context.auth.message.forgot) {
      return(
        <div className="form-reset message">

          <p className="text-center">Veuillez vérifier votre adresse e-mail pour connaître<br/>le lien permettant de réinitialiser votre mot de passe.</p>

          <button onClick={() =>  backFromReset()} className="btn primary">Retour au login</button>
        </div>
      )

    } else {
      return (
        <form className="form-reset" onSubmit={handleSubmit(submitReset)}>
        
          <label htmlFor="email">Adresse email</label>
          <input
            name="email"
            ref={register({
              required: "Compléter votre email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Adresse mail invalide"
              }
            })}
          />

          {errorForgot? 
            <p className="text-error text-center" style={{ margin: 5}}>{errorForgot}</p>
            :
            <p className="text-error text-center">{errors.email && errors.email.message}</p>
          }
          
          <div className="btn-container">
            <button className="btn primary" type="submit">Réinitialiser le mot de passe</button>
            <button onClick={() => setDisplayLogin(true)} className="btn-reset">Retour au login</button>
          </div>
      
        </form>
      )
    }
  }
  
  return (
    <div className="page-container login">

      <div className="col-img"></div>

      <div className="col-form">

        <div className="logo">
          <img src={logo} alt="logo" />
        </div>

        {displayLogin ?
          renderLoginForm()
          :
          renderResetForm()
        }

      </div>

    </div>
  )
}
