import React , { useEffect } from 'react';
import { Link } from  'react-router-dom';
import { ERROR_QUOTES } from '../../../../../actions/types';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { quotesStates } from '../../../../../utils/constants';
import { launchMissionAction } from '../../../../../actions/quotesActions';
import { getManageAction } from '../../../../../actions/usersActions';
import Button from '../../../../partials/Button/Button';
import styles from './QuoteInfo.module.scss';

export default function QuoteInfo({ quote }) {

  const [context , dispatch ] = useGlobalContext();

  const isActive = context.auth?.toast?.message === "Votre compte est activé" ? true : false;

  const entityId = context.cases?.entity?._id;

  function renderQuoteState() {
    const state = quotesStates.find(d => d.value.name === "waiting");
    if (state?.value?.name === "waiting") {
      return <span><br/>est <span className="bold" style={{ color: state?.value?.color }}>en cours  de vérification</span></span>
    }
  }

  useEffect(() => {
    dispatch({
      type: ERROR_QUOTES,
      payload: null
    });
  },[dispatch]);

  return (
    <>
      {!isActive && quote.state !== "valid" &&
        <>
            <div className={styles.container}>
              <div className={styles.info}>
                {quote.state === "signature" && <p>Votre lettre de mission est en attente de signature. Vous pouvez dès à présent renseigner un moyen de paiement qui sera utilisé par la suite</p> }
                {quote.state === "signed" && <p>Votre lettre de mission est bien signée. Vous pouvez dès à présent démarrer votre abonnement !</p> }
                {(quote.state === "waiting" || !quote.state )  && <p>Votre de demande de devis #{quote?.number} {renderQuoteState()}</p> }
              </div>

              {quote.state === "signature" && 
                <Button
                  onClick={() => getManageAction(dispatch, entityId)}
                  primary
                  >
                  Renseigner un moyen de paiement
                </Button>
              }
              
              {quote.state === "signed" && context.quotes.error?.data?.error !== "No paiement method found" && 
                <Button
                  onClick={() => launchMissionAction(dispatch, quote._id )}
                  primary
                  >
                  Activer votre compte
                </Button>
              }
              {quote.state === "signed" && context.quotes.error?.data?.error === "No paiement method found" && 
                <Button
                  onClick={() => getManageAction(dispatch , entityId)}
                  primary
                  >
                  Veuillez renseigner un moyen de paiement
                </Button>
              }
              {(quote.state === "waiting" || !quote.state ) &&
                <Link to={`/member/tools/${entityId}/quote/${quote._id}`}>
                  <Button
                    primary
                    >
                    Consulter le résumé de votre demande
                  </Button>
                </Link>
              }
            </div>
        </>
      }
      </>
  )
}
