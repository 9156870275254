import {
  IS_AUTH,
  ERROR_AUTH,
  USER_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PUT_USER_AUTH,
  LOGIN,
  MESSAGE_AUTH,
  SET_TOAST
} from "../actions/types";

const initialState = {
  isAuth: false,
  error: null,
  message: null,
  userAuth: null,
  toast: null
};

export default function authReducer(state = initialState, action) {

  switch (action.type) {
    case SET_TOAST:
      return {...state , toast: action.payload}
    case MESSAGE_AUTH:
        return {...state , message: action.payload}
    case LOGIN:
      return {...state , isAuth: true }
    case IS_AUTH:
      return {...state , isAuth: action.payload}
    case ERROR_AUTH:
      return {...state , error: action.payload}
    case PUT_USER_AUTH:
      return {...state , toast: { message : "le profile a été mis à jour"}}
    case USER_AUTH:
      return {...state , userAuth: action.payload}
    case FORGOT_PASSWORD:
      return {...state , message : { forgot: action.payload.message}}
    case RESET_PASSWORD:
      return {...state , message : { reset: action.payload.message}}
    default:
      return {...state};
  }
}