import React , { useEffect } from 'react';
import { Switch, Route , useLocation , useHistory } from "react-router-dom";
import { useTransition, animated } from 'react-spring';
import { useGlobalContext } from '../../../context/GlobalContext';

import Nav from './partials/Nav/Nav';

import AccoutPage from './Account/AccountPage';
import QuotePage from './Quotes/QuotePage/QuotePage';
import EditQuotePage from './Quotes/QuotePage/EditQuotePage';
import QuotesListPage from './Quotes/QuotesListPage/QuotesListPage';
import UserListPage from './Users/UserListPage/UserListPage';
import MeetsListPage from './Meets/MeetsListPage/MeetsListPage';
// import UserPage from './Users/UserPage/UserPage';
import MeetPage from './Meets/MeetPage/MeetPage';
import CasesListPage from './Cases/CasesListPage/CasesListPage';
import CasePage from './Cases/CasePage/CasePage';

export default function AdminRouter({ logout }) {

  const context = useGlobalContext()[0];
  const { userAuth } = context.auth;
  const history = useHistory();

  useEffect(() => {
    if (userAuth) {
      if (userAuth.role === "Member" && userAuth?.entities?.length > 0) {
        history.push(`/member/tools/${userAuth?.entities[0]}`);
      }
    }
  },[userAuth , history]);

  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 1, transform: 'translate3d(-120%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(120%, 0,0)'},
  });



  return (
    <div className="page-container">
      <Nav
        logout={logout}
        />
      {transitions.map(({ item: location, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={location}>

            <Route exact path={'/admin/user'} render={
              () => <UserListPage />
            }/>
{/* 
            <Route exact path={`/admin/user/:userId`} render={
              () => <UserPage />
            }/> */}

            <Route exact path={`/admin/account`} render={
              () => <AccoutPage />
            }/>

            <Route exact path={`/admin/quote`} render={
              () => <QuotesListPage />
            }/>

            <Route exact path={`/admin/quote/edit/:entityId/:quoteId`} render={
              () => <EditQuotePage />
            }/>

            <Route exact path={'/admin/quote/create/:entityId'} render={
              () => <EditQuotePage />
            }/>


            <Route exact path={`/admin/quote/view/:quoteId`} render={
              () => <QuotePage />
            }/>

            <Route exact path={`/admin/meet`} render={
              () => <MeetsListPage />
            }/>

            <Route exact path={`/admin/meet/:meetId`} render={
              () => <MeetPage />
            }/>

            <Route exact path={`/admin/cases`} render={
              () => <CasesListPage />
            }/>

            <Route exact path={`/admin/cases/:caseId/:entityId?`} render={
              () => <CasePage />
            }/>

          </Switch>
        </animated.div>
      ))}
    </div>
  )
}
