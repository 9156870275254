import {
  ERROR_USERS,
  MESSAGE_USERS,
  GET_USERS,
  GET_USER,
  PUT_USER,
  POST_USER,
  POST_MEET,
  DELETE_USER,
  CLEAR_USER
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  usersList: [],
  adminsList: [],
  user: null,
  url: null,
  userQuotes: null,
  userAppointments: null
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {

    case POST_USER:
      let createdList = [...state.usersList];
      createdList.push(action.payload.user);
      return {...state ,
        usersList : createdList,
        user : action.payload.user,
        message: "user created"
      }
      
    case POST_MEET :
      let usersMeets = state.userAppointments.length > 0 ? state.userAppointments : [];
      usersMeets.push(action.payload.appointment);
      return {...state , userAppointments: usersMeets  } 

    case PUT_USER:
      let updatedList = state.usersList.filter(user => user._id !== action.payload.user._id);
      updatedList.push(action.payload.user)
      return {...state ,
        usersList : updatedList,
        user : action.payload.user,
        message: action.payload.message
      }

    case CLEAR_USER:
      return {...state , user: null , url: null ,userQuotes: null , userAppointments: null }

    case GET_USER:
      return {...state , user: action.payload.user , url: action.payload.url ,userQuotes: action.payload.quotes , userAppointments: action.payload.appointments }

    case DELETE_USER:
      var deleteList = state.usersList.filter(user => user._id !== action.payload)
      return {...state , usersList: deleteList , message: 'user removed'}

    case GET_USERS:
      return {...state , usersList: action.payload, adminsList: action.payload.filter(item => item.role === "Admin") }

    case MESSAGE_USERS:
      return {...state , message: action.payload}

    case ERROR_USERS:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}