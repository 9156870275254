import { postData , deleteData } from './index';

import {
  POST_FILE,
  DELETE_FILE,
  ERROR_UPLOAD
} from "./types"

export async function postFileAction(dispatch , formData ){
  let url = "/file",
      file;

  await postData(POST_FILE , ERROR_UPLOAD , url, dispatch , formData , true ).then((response)=>{
    file = response
  });

  dispatch({
    type : POST_FILE ,
    payload : file.files[0]
  });

};

export async function deleteFileAction(dispatch , id ){
  let url = "/file/" + id,
      file;

  await deleteData(ERROR_UPLOAD, url, dispatch  ).then((response)=>{
    file = response
  });

  dispatch({
    type : DELETE_FILE ,
    payload : {
      file: null,
      message: `${file.message} ${id}`
    }
  });

};