import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useGlobalContext } from '../../../context/GlobalContext';
import { getCalendarAction, postMeetAction } from '../../../actions/meetsActions';

import Button from '../Button/Button';
// import DateTimePicker from '../DatePicker/DateTimePicker';

import styles from './MeetModale.module.scss';
import CustomSelect from '../Form/Select/CustomSelect';
import CheckBox from '../Form/CheckBox/CheckBox';
import SelectMeet from '../SelectMeet/SelectMeet';
import Loader from '../Loader/Loader';
import { daysWords } from '../../../utils/constants';

export default function MeetModale({ client , closeModale }) {

  const [ context , dispatch ] = useGlobalContext();

  const { calendar } = context.meets;

  const { handleSubmit, errors , setValue , control } = useForm();

  // const [ date , setDate ] = useState(new Date());
  const [ selectedMeet , setSeletedMeet ] = useState();
  const [ isLoading , setIsLoading ] = useState(true);

  const getCalendar = useCallback(() => {
    getCalendarAction(dispatch);
  },[dispatch]);

  const adminsOptions = context.users?.adminsList?.map(a => ({ value: a._id , label: `${a.profile.firstName} ${a.profile.lastName}` }))

  function submitMeet(value) {
    let startDate = new Date(selectedMeet.date);
    let data = {
      client : client,
      startDate : startDate,
      endDate : new Date(startDate.getTime() + (30 * 60 * 1000)),
      user : value.user,
      withInvitation: value.withInvitation
    };
    postMeetAction(dispatch , data);
    closeModale();
  }

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  useEffect(() => {
    if (calendar) {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  },[calendar]);

  return (
    <form
      onSubmit={handleSubmit(submitMeet)}
      className={styles.container}>
      <h2>Ajouter un rendez-vous</h2>
      <div className={`${styles.row} ${styles.top}`}>
        {/* <div className={styles['col-2']}>
          <Controller
            name="date"
            rules={{ required: "la date est requise" }}
            control={control}
            defaultValue={date}
            render={(props) =>
              <DateTimePicker
                label={""}
                handleChange={(val) => handleChange(val)}
                withErrors={true}
                date={date}
                error={errors.date && errors.date.message}
                />
            }
          />
        </div> */}
        <div className={styles['col-1']}>
          <Controller
            name="user"
            rules={{ required: "le conseiller est requis" }}
            control={control}
            defaultValue={null}
            render={(props) =>
              <CustomSelect
                placeholder={"Séléctionner un conseiller"}
                handleChange={(opt) => setValue('user' , opt.value)}
                options={adminsOptions}
                withErrors={true}
                error={errors.user && errors.user.message}
                />
            }
          />
        </div>
      </div>
      <div className={`${styles.row} ${styles.calendar}`}>
        {isLoading &&
          <div className={styles.loader}>      
            <Loader size={40} isLoading={true} color={'#4AC3C9'}/>
          </div>
        }
        {!isLoading && calendar &&
          <SelectMeet
            calendar={calendar}
            selectedMeet={selectedMeet}
            setSeletedMeet={setSeletedMeet}
            days={daysWords}/> 
        }
      </div>
      <div className={styles.row}>
        <div className={styles['col-2']}>
          <Controller
            name="withInvitation"
            control={control}
            defaultValue={true}
            render={(props) =>
              <CheckBox
                label={"avec invitation"}
                defaultValue={true}
                onChange={(val) => setValue('withInvitation' , val)}
                withErrors={true}
                error={errors.withInvitation && errors.withInvitation.message}
                />
            }
          />
        </div>
      </div>
      {selectedMeet
        && <Button
        primary
        type={'submit'}
        >
        Valider
      </Button>
      }
    </form>
  )
}
