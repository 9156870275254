import React , { useEffect , useState , useCallback } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiOutlineFileDone  , AiFillDelete } from 'react-icons/ai';
import { API_URL } from '../../../actions/index';

import { postFileAction , deleteFileAction } from '../../../actions/filesActions';
import { putUserAction } from '../../../actions/usersActions';
import { putQuoteAction , sendMissionLetterToSignatureAction , getMissionLetterAction } from '../../../actions/quotesActions';

import { useGlobalContext } from '../../../context/GlobalContext';

import Input from '../Form/Input/Input';
import Button from '../Button/Button';
import CustomSelect from '../Form/Select/CustomSelect';
import InputFile from '../Form/InputFile/InputFile';

import styles from './DocumentModale.module.scss';
import { putEntityAction } from '../../../actions/entityActions';

export default function DocumentModale({  entity , quote , setModaleIsActive }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors , setValue , watch ,  control } = useForm();

  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();
  const [ fileId , setFileId ] = useState();

  const { file , error } = context.files

  const userOptions = ['Inscription' , 'Bilan' , 'Autre'].map(d => ({ value:  d , label :d }) );

  const quoteOptions = ['Lettre de mission', 'Lettre de mission sans création d’entreprise' , 'Contrat' ].map(d => ({ value:  d , label :d }) );

  const typesOptions = entity ? userOptions : quoteOptions ;

  const needFile = ['Inscription' , 'Bilan' , 'Autre' , 'Contrat'];

  const clearFile = useCallback(() => {
    setFileId();
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch])

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    register("type");
    register('file');
  }, [register]);

  useEffect(()=> {
    if (file && file._id) {
      setValue('file' , file._id );
      setFileId(file._id);
      if (uploadedFile) document.getElementById('file').value = "";
      setUploadedFile();
      setFileError();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(() => {
    if (errors.file && errors.file.message) {
      setFileError(errors.file.message);
    }
  },[errors.file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Ce type de fichier n'est pas autorisé");
        setUploadedFile();
        setFileId();
        dispatch({
          type : 'ERROR_UPLOAD',
          payload : null,
        });
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData ) {

    setUploadedFile(fileData);
    setFileError();
    setFileId();

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", watch('title') || watch('type') );
    formData.append("type", watch('type') );
    formData.append("description", watch('type') );
    formData.append("alt", `${fileData.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData )

  }

  function closeModale() {
    setModaleIsActive(false);
    deleteFile()
  }

  function deleteFile() {
    if (fileId) {
      deleteFileAction(dispatch , fileId );
      setFileId();
    }
  }

  function submitForm() {
    
      if (entity && entity._id && file?._id) {
        let updatedList = entity.files ? entity.files.map(d => d._id) : [];
        updatedList.push(file._id);
        putEntityAction(dispatch, entity._id , { files: updatedList })
        // putUserAction(dispatch , user._id , { files: updatedList });
      } ;

      if (quote && quote._id) {
        if (watch('type') === "Lettre de mission") {
          sendMissionLetterToSignatureAction(dispatch , "", quote._id );
        };
        if (watch('type') === "Lettre de mission sans création d’entreprise") {
          sendMissionLetterToSignatureAction(dispatch , "withoutcompany" , quote._id );
        };
        if (watch('type') === "Contrat" && file._id ) {
          putQuoteAction(dispatch , quote._id , { contract: file._id });
        };
      };
      setModaleIsActive(false);
  }

  function renderCreate() {
    return (
        <>
          <h2>Ajouter un document</h2>
          <form onSubmit={handleSubmit(submitForm)}>

            {typesOptions &&
              <Controller
                name="type"
                rules={{ required: "le type est requis" }}
                control={control}
                render={(props) =>
                  <CustomSelect
                    label={"Type du document"}
                    defaultValue={null}
                    handleChange={(opt) => setValue('type' , opt.label )}
                    options={typesOptions}
                    withErrors={true}
                    error={errors.type && errors.type.message}
                    />
                }
                defaultValue={null}
              />
            }
            
            {watch('type') && needFile?.find((f) => f === watch('type')) &&
              <Input
                label={"Nom du document"}
                placehloder={"Titre"}
                name={"title"}
                register={register({
                  required: "Nom du document",
                })}
                onChange={(val) => setValue('title' , val )}
                withErrors={true}
                error={errors.title && errors.title.message}
              />
            }

            {watch('type') && needFile?.find((f) => f === watch('type')) && watch('title') &&
              <>
                <label>Fichier</label>

                {file && file._id  &&
                  <div className={styles.file}>
                    <a href={`${API_URL}/${file.path}`} target="_blank" rel="noreferrer">
                      <div className={styles.link}>
                        <AiOutlineFileDone size={20} color={'#FFFFFF'} />
                        <p>{file.name}</p>
                      </div>
                  </a>
                  <button
                    type="button"
                    onClick={() => deleteFile(fileId)}
                    className={`${styles.delete}`}>
                    <AiFillDelete size={20} color={'red'} />
                    <p>supprimer</p>
                  </button>
                  </div>
                }
                  
                {!fileId && 
                  <Controller
                    name="file"
                    rules={{ required: "Le ficher est requis" }}
                    control={control}
                    defaultValue={null}
                    render={(props) =>
                      <InputFile
                        name="file"
                        isLoading={uploadedFile}
                        withErrors={true}
                        defaultValue={null}
                        onChange={(files) => handleChangeFile(files[0])}
                        error={fileError}
                        />}
                  />
                }
              </>
            }

            <div className={styles.actions}>
              {(!needFile?.find((f) => f === watch('type')) || fileId ) &&
                <Button
                  type={"submit"}
                  primary
                  >
                  Valider
                </Button>
              }
              <Button
                type={"button"}
                primary
                outline
                onClick={() => closeModale()}
                >
                Annuler
              </Button>
            </div>
          </form>
      </>
    )
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      {renderCreate()}
    </div>
  )
}
