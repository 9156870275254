import React from 'react';
import { Link , useParams } from 'react-router-dom';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { useGlobalContext } from '../../../context/GlobalContext';
import styles from './ActiveUser.module.scss';

export default function ActiveUser({ logout }) {
  
  const context = useGlobalContext()[0];
  const { entityId } = useParams();

  const user = context.auth.userAuth

  let profile = user && user.profile && user.profile

  return (
    <div className={`${styles.user} bold`}>
      <Link to={user?.role === "Admin" ? '/admin/account' : `/member/account/${entityId}`}>
        <div className={styles.name}>
          <div className={styles.avatar}>
            {profile && profile.firstName && profile.firstName.charAt(0)}
            {profile && profile.lastName && profile.lastName.charAt(0)}
          </div>
          <p>{profile && profile.firstName}</p>
        </div>
      </Link>
      <button onClick={() => logout()}>
        <AiOutlinePoweroff size={21} color={'#C6CBD8'} />
      </button>
    </div>
  )
}
