import React ,{ useCallback , useEffect  , useState } from 'react'

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { postQuotesFilteredAction } from '../../../../../actions/quotesActions';

import Input from '../../../../partials/Form/Input/Input';
import SortButton from '../../../../partials/SortButton/SortButton';
import CustomSelect from '../../../../partials/Form/Select/CustomSelect';
import QuoteRow from './QuoteRow/QuoteRow';

import styles from './QuotesListPage.module.scss';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SET_QUOTE_QUERY, SET_QUOTE_SCROLL } from '../../../../../actions/types';

export default function QuotesListPage() {

  const [ context , dispatch ] = useGlobalContext();
  const { quotesList , query , count , lists, scrollY } = context.quotes;
  const { usersList } = context.users ;

  const listRef = useRef(null);
  const scrollRef = useRef(null);

  const { register, watch , setValue , control } = useForm();

  const states = watch('states');

  const [ filtredList , setFiltredList ] = useState();
  const [ search , setSearch ] = useState('');
  const [ isLoading, setIsLoading] = useState(true);

  const quotesStates = [
    { 
      value: 'valid' ,
      label: 'Validés'
    },
    { 
      value: 'waiting' ,
      label: 'En cours'
    },
    { 
      value: 'refused' ,
      label: 'Refusés'
    },
  ];

  const initSortState = {
    number: true,
    name: true,
    date: true,
    code: true,
    state: true,
    amountHT: true
  }

  const [ sortByReverse , setSortByReverse ] = useState(initSortState);

  const getDatasFiltered = useCallback(() => {
    setIsLoading(true);
    postQuotesFilteredAction(dispatch , query);
  },[dispatch, query]);

  const setFilters = useCallback((updatedQuery) => {
    dispatch({
      type: SET_QUOTE_QUERY,
      payload: updatedQuery,
    }) 
  },[dispatch]);


  useEffect(() => {
    if (quotesList && usersList) {
      setIsLoading(false);
      setFiltredList(quotesList);
    }
  },[quotesList , usersList]);


  function sortBy(key , isReverse ) {
    setSortByReverse({...initSortState , [key]: isReverse })
    dispatch({
      type:SET_QUOTE_QUERY,
      payload: {
        ...query,
        page: 1,
        orderBy: { 
          key,
          order: isReverse ? "asc" : "desc",
        }
      }
    }) 
  }

  function handleScroll(e) {
    dispatch({
      type: SET_QUOTE_SCROLL,
      payload: e.target.scrollTop,
    });
    if (!isLoading
        && count > quotesList?.length
        && scrollRef?.current.getBoundingClientRect().bottom
        === listRef?.current.getBoundingClientRect().bottom
    ) {
      dispatch({
        type: SET_QUOTE_QUERY,
        payload: {
          ...query,
          page: query.page + 1,
        },
      });
    }
  }

  useEffect(() => {
    register('states');
  }, [register]);


  useEffect(() => {
    getDatasFiltered();
  }, [getDatasFiltered, query.page , query.states]);

  useEffect(() => {
    let timer;
    if (search?.length > 3) {  
      setFilters({
        ...query,
        page: 1,
        search,
      });
    } else {
      setFilters({
        ...query,
        page: 1,
        search: '',
      });
    }
    return () => clearTimeout(timer);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setFilters({
      ...query,
      page: 1,
      states: states,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[states]);


  useEffect(() => {
    if (query.page === 1) {
      listRef.current.scrollTop = 0;
    }
  }, [query.page]);

  useEffect(() => {
    if (scrollY) {
      listRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
  }, [quotesList, scrollY]);

  return (
    <div className="page-content">
      <div className={`${styles['header']}`}>
        <div className={`${styles['row']}`}>
          <div className={styles['col-3']}>
            <Input
              value={search}
              onChange={(val) => setSearch(val) }
              placehloder="Recherche"
            />
          </div>
          <div className={styles['col-3']}>
          {quotesStates &&
            <Controller
              name="states"
              rules={{ }}
              control={control}
              defaultValue={lists?.states.filter((o) => query?.states?.find((u) => u === o.value)) || []}
              render={(props) =>
                <CustomSelect
                  label={""}
                  defaultValue={lists?.states.filter((o) => query?.states?.find((u) => u === o.value))}
                  isMulti
                  handleChange={(array) => setValue('states' , array?.map((opt) => opt.value) )}
                  options={quotesStates}
                  withErrors={false}
                  />
              }
              />
            }
          </div>
        </div>
        <div className={`${styles['list-row']} ${styles['sort']}`}>
          <SortButton name={"Numéro du devis"} action={() => sortBy('number' , !sortByReverse.number )} state={sortByReverse.number} className={styles['col']}/>
          <SortButton name={"Entité"} action={() => sortBy('name' , !sortByReverse.name )} state={sortByReverse.name} className={styles['col']}/>
          <SortButton name={"Code"} action={() => sortBy('code' , !sortByReverse.code )} state={sortByReverse.code} className={`${styles['col']} ${styles['state']}`}/>
          <SortButton name={"Montant HT"} action={() => sortBy('amountHT' , !sortByReverse.amountHT )} state={sortByReverse.amountHT} className={`${styles['col']} ${styles['state']}`}/>
          <SortButton name={"Date"} action={() => sortBy('date' , !sortByReverse.date )} state={sortByReverse.date} className={`${styles['col']} ${styles['state']}`}/>
          <SortButton name={"État"} action={() => sortBy('state' , !sortByReverse.state )} state={sortByReverse.state} className={`${styles['col']} ${styles['state']}`}/>
        </div>
      </div>
      <div
        ref={listRef}
        onScroll={(e) => handleScroll(e)}
        className={styles.list}
      >
        <div
          className={styles.content}
          ref={scrollRef}>
          {filtredList?.map((quote , i ) => <QuoteRow key={quote._id} styles={styles} quote={quote} />)}
        </div>
      </div>
    </div>
  )
}
