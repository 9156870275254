import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

export default function MeetRow({ styles , meet }) {

  return (
    <Link to={`/admin/meet/${meet && meet._id}`} className={`${styles['list-row']} ${styles['list-item']}`}>
      <div className={styles['col']}>
        {meet?.client?.name &&
          <p><span className="uppercase">{meet?.client?.name}</span></p>
        }
      </div>
      <div className={`${styles['col']}`}>
        <p><span className="uppercase">{meet.startDate && format(new Date(meet.startDate) , 'dd/MM/yyyy HH:mm')}</span></p>
      </div>
      <div className={`${styles['col']}`}>
        <p><span className="uppercase">{meet?.user?.profile?.lastName} {meet?.user?.profile?.firstName}</span></p>
      </div>
    </Link>
  );
}
