import {
  ERROR_QUOTES,
  GET_QUOTES,
  GET_QUOTE,
  PUT_QUOTE,
  POST_QUOTE,
  DELETE_QUOTE,
  DELETE_USER,
  RESET_QUOTE,
  GET_QUOTES_FILTERED,
  SET_QUOTE_SCROLL,
  SET_QUOTE_QUERY,
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  quotesList: [],
  quote: null,
  query: {
    search:"",
    states:[],
    page:1,
    orderBy: {
      key: "name",
      order: "desc"
    },
    number: 20
  }
};

export default function quotesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_QUOTES_FILTERED:
      let newCases = state.quotesList;
      const { page } = state.query;
      if (page === 1) {
        newCases = [...action.payload.quotes]
      } else {
        newCases = [...newCases,...action.payload.quotes]
      }
      return {...state , quotesList: newCases, count: action.payload.count };

    case SET_QUOTE_QUERY:
      return {...state, query: action.payload };

    case SET_QUOTE_SCROLL:
      return {...state, scrollY: action.payload };

    case POST_QUOTE:
      let postList = state.quotesList;
      postList.push(action.payload.quote)
      return {...state ,
        quotesList : postList,
        quote : action.payload.quote,
        message: action.payload.message
      };

    case PUT_QUOTE:
      let updatedList = state.quotesList.filter(quote => quote._id !== action.payload.quote._id);
      updatedList.push(action.payload.quote)
      return {...state ,
        quotesList : updatedList,
        quote : action.payload.quote,
        message: action.payload.message
      };
    case RESET_QUOTE:
      return {...state , quote: action.payload };

    case GET_QUOTE:
      return {...state , quote: action.payload.quote };
      
    case DELETE_USER:
      var deleteListUser = state.quotesList.filter(quote => quote.client._id !== action.payload)
      return {...state , quotesList: deleteListUser , message: 'quote removed'};

    case DELETE_QUOTE:
      var deleteList = state.quotesList.filter(quote => quote._id !== action.payload)
      return {...state , quotesList: deleteList , message: 'quote removed'};

    case GET_QUOTES:
      return {...state , quotesList: action.payload};

    case ERROR_QUOTES:
      return {...state , error: action.payload};
      
    default:
      return {...state};
  }
}