import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { quotesStates } from '../../../../../../utils/constants';

export default function QuoteRow({ styles , quote }) {

  const state = quotesStates.find(d => d.value.name === quote.state);

  return (
    <Link to={`/admin/quote/view/${quote._id}`} className={`${styles['list-row']} ${styles['list-item']}`}>
      <div className={styles['col']}>
        <p className="bold"><span className="uppercase">#{quote.number}</span></p>
      </div>
      <div className={styles['col']}>
        <p><span className="uppercase">{quote?.client?.name}</span></p>
      </div>

      <div className={`${styles['col']} ${styles['state']}`}>
        <p className="bold">{quote?.code && quote.code} </p>
      </div>
      
      <div className={`${styles['col']} ${styles['state']}`}>
        <p className="bold">{quote?.amountHT ? quote.amountHT + "€" : "--- €"} </p>
      </div>

      <div className={`${styles['col']} ${styles['state']}`}>
        <p><span className="uppercase">{format(new Date(quote.createdAt) , 'dd/MM/yyyy')}</span></p>
      </div>

      <div className={`${styles['col']} ${styles['state']}`}>
        <p className="bold" style={{ color: state.value.color }}>{state.label}</p>
      </div>

    </Link>
  );
}
