import { utils, writeFile } from 'xlsx';
import { getData , putData , postData , deleteData } from './index';

import {
  ERROR_USERS,
  GET_USERS,
  GET_USER,
  PUT_USER,
  POST_USER,
  DELETE_USER,
  SET_TOAST
} from "./types"


export async function getUsersListAction(dispatch) {
  let url = "/user";
  var users;

  await getData(ERROR_USERS, url, dispatch , true ).then((response)=>{
    users = response.users
  });

  dispatch({
    type : GET_USERS,
    payload : users
  });

};

export async function getUserAction(dispatch , id) {
  
  let url = "/user/" + id ;
  var user;

  await getData(ERROR_USERS, url, dispatch , true ).then((response)=>{
    user = response
  });

  dispatch({
    type : GET_USER,
    payload : user
  });

};

export async function postUserAction(dispatch , data ) {
  let url = "/user" ;
  var user;

  await postData( POST_USER , ERROR_USERS ,  url , dispatch, data , true ).then((response)=>{
    user = response
  });

  dispatch({
    type : POST_USER,
    payload : user
  });

};

export async function putUserAction(dispatch , id , data ) {
  let url = "/user/" + id;
  var user;

  await putData(PUT_USER, ERROR_USERS ,  url , dispatch, data , true ).then((response)=>{
    user = response
  });

  dispatch({
    type : PUT_USER,
    payload : user
  });

};

export async function getManageAction(dispatch , entityId ) {
  
  let url = `/quote/${entityId}/manage` ;
  var manage;

  await getData(ERROR_USERS, url, dispatch , true ).then((response)=>{
    manage = response.url
  });

  window.open(manage);

};


export async function deleteUserAction(dispatch , id) {
  let url = "/user/" + id;

  await deleteData(ERROR_USERS, url, dispatch, true ).then((response)=>{
    
    dispatch({
      type : DELETE_USER,
      payload : id
    });

    dispatch({
      type : SET_TOAST,
      payload : {
        type: "success",
        message: "l'utilisateur a été supprimé"
      }
    });

  });

};

export async function exportUsersAction(dispatch) {
  let url = "/export/users";
  var cases;

  await getData(ERROR_USERS, url, dispatch , true ).then((response)=>{
    cases = response.obj.data;
  });
  const worksheet = utils.json_to_sheet(cases);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Utilisateurs");
  writeFile(workbook, "utilisateurs.xlsx", { compression: true });

};