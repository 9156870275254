import React , { useState ,useEffect , useCallback } from 'react';
import { Link , useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { getQuoteAction , putQuoteAction  , getMissionLetterAction } from '../../../../../actions/quotesActions';
import { deleteFileAction } from '../../../../../actions/filesActions';

import { API_URL } from '../../../../../actions/index';

import { quotesStates , labels, getActivity } from '../../../../../utils/constants';

import { HiArrowLeft } from 'react-icons/hi';
import { MdPhoneForwarded , MdMail , MdDelete , MdHome } from 'react-icons/md';
import Button from '../../../../partials/Button/Button';
import Modale from '../../../../partials/Modale/Modale';
import DocumentModale from '../../../../partials/DocumentModale/DocumentModale';

import styles from './QuotePage.module.scss';
import CustomSelect from '../../../../partials/Form/Select/CustomSelect';

export default function QuotePage() {

  const [ context , dispatch ] = useGlobalContext();
  const { quoteId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const caseUrl = searchParams.get('caseUrl');

  const { quote } = context.quotes ;
  const { userAppointments } = context.users ;

  const token = localStorage.getItem('token').replace('JWT ', '');

  const [ fileModaleIsActive  , setFileModaleIsActive ] = useState();
  
  const getData = useCallback(() => {
    getQuoteAction(dispatch , quoteId);
  },[dispatch , quoteId]);

  useEffect(() => {
    getData();
    return dispatch('CLEAR_USER');
    // eslint-disable-next-line
  },[getData]);

  function handleChangeState(val) {
    putQuoteAction(dispatch , quote._id , { state: val })
  }

  function deleteFile( key ,  id ) {
    putQuoteAction(dispatch , quoteId , { [key]: null } );
    deleteFileAction(dispatch , id );
  }

  return (

    <div className={`page-content ${styles.container} `}>
      {quote &&
        <>
          <header className={`${styles.header}`}>
            {caseUrl ?
              <Link to={`${caseUrl}`} className={styles.back} >
                <HiArrowLeft  size={20}/>  <span className="bold">Retour au dossier</span>
              </Link>
            :
              <Link to={'/admin/quote'} className={styles.back} >
                <HiArrowLeft  size={20}/>  <span className="bold">Retour à la liste</span>
              </Link>
            }
            <div className={styles.state}>
              {quotesStates.find(opt => opt.value.name === quote.state) &&
                <CustomSelect
                  defaultValue={quotesStates.find(opt => opt.value.name === quote.state)}
                  handleChange={(val) => handleChangeState(val.value.name)}
                  options={quotesStates}
                  />
              }
            </div>
            <div className={styles.content}>
              <Link to={`/admin/quote/edit/${quote.client._id}/${quoteId}/${search}`} >
                <Button
                  primary
                  >
                  MODIFIER
                </Button>
              </Link>

              <div className={`${styles.row} ${styles.profile} ${styles.quote}`}>
                <div className={`${styles['col-3']} ${styles.number}`}>
                  <p className={styles.label}>Devis n°</p>
                  <h1 className="bold">{quote.number}</h1>
                </div>

                <div className={`${styles['col-3']} ${styles.number}`}>
                  <p className={styles.label}>Montant HT</p>
                  <h1 className="bold">{quote?.amountHT ? quote.amountHT + "€" : "--- €"} </h1>
                </div>

                {quote?.code &&
                  <div className={styles['col-3']}>
                    <p className={styles.label} >Code promo</p>
                    <p className="bold">{quote?.code}</p>
                  </div>
                }
              </div>

              <div className={`${styles.row} ${styles.profile} ${styles.quote}`}>

                <div className={styles['col-3']}>
                  <p className={styles.label}>Nom</p>
                  <Link to={`/admin/cases/${quote.client.case}/${quote.client._id}`} >
                    <p className="bold">{quote?.client?.name}</p>
                  </Link>
                </div>
                {console.log(quote)}

                <div className={styles['col-3']}>
                  <p className={styles.label}>Type d’activité</p>
                  <p className="bold">{quote.activity && getActivity(quote.activity)}</p>
                </div>

                {quote?.companyType &&
                  <div className={styles['col-3']}>
                    <p className={styles.label}>Type d'entreprise</p>
                    <p className="bold">{quote?.companyType}</p>
                  </div>
                }

              </div>

            </div>
          </header>
          <main className={`${styles.main}`}>
            <div className={styles.content}>

              {userAppointments?.length > 0 && 
                <section>
                  <h3>Rendez-vous</h3>
                  <div className={styles.quotes}>
                  {userAppointments.map((a , i) =>
                    <Link key={"link" + i } to={`/admin/meet/${a._id}`} className={styles.quote}>
                      {a.startDate && <p>{format(new Date(a.startDate) , 'dd/MM/yyyy HH:mm')}</p>}
                    </Link>
                  )}
                  </div>
                </section>
              }

              <section>

                <div className={`${styles.row}`}>
                  <div className={styles['col-3']}>
                    <p className={styles.label}>Date de début de mission</p>
                    <p className="bold">{quote.startDate ? format(new Date(quote.startDate) , 'dd/MM/yyyy') : "Aucune"}</p>
                  </div>
                
                  <div className={styles['col-3']}>
                    <p className={styles.label}>Date de clôture</p>
                    <p className="bold">{quote.closingDate ? format(new Date(quote.closingDate) , 'dd/MM/yyyy') : "Aucune"}</p>
                  </div>
                </div>

                <div className={`${styles.row}`}>
                  {quote?.turnover  &&
                    <div className={styles['col-3']}>
                      <p className={styles.label}>Chiffre d'affaires</p>
                      <p className="bold">{quote?.turnoverValue ? quote?.turnoverValue + ' €' : labels['turnover'][quote.turnover]}</p>
                    </div>
                  }
                  {quote?.employee > 0 &&
                    <div className={styles['col-3']}>
                      <p className={styles.label}>Nombre de salariés</p>
                      <p className="bold">{quote?.employee}</p>
                    </div>
                  }
                  {quote?.administrative &&
                    <div className={styles['col-3']}>
                      <p className={styles.label}>Organisation administrative</p>
                      <p className="bold">{labels['administrative'][quote.administrative]}</p>
                    </div>
                  }
                </div>


                <div className={`${styles.row}`}>
                  {quote?.type &&
                    <div className={styles['col-3']}>
                      <p className={styles.label}>Type d'entreprise</p>
                      <p className="bold">{labels['type'][quote.type]}</p>
                    </div>
                  }
                  {quote?.launchState &&
                    <div className={styles['col-3']}>
                      <p className={styles.label}>Status de lancement</p>
                      <p className="bold">{labels['launchState'][quote.launchState]}</p>
                    </div>
                  }
                  <div className={styles['col-3']}>
                    <p className={styles.label}>Besoin d'accompagnement</p>
                    <p className="bold">{quote?.needAccompaniment ? "Oui" : "Non"}</p>
                  </div>
                </div>

                <div className={`row ${styles.row}`}>
                  {quote?.isTaxed &&
                    <div className={styles['col-3']}>
                      <p className={styles.label}>Type d’exercice</p>
                      <p className="bold">{labels['isTaxed'][quote.isTaxed]}</p>
                    </div>
                  }
                  {quote?.tvaPeriodicity &&
                    <div className={styles['col-3']}>
                      <p className={styles.label}>TVA</p>
                      <p className="bold">{labels['tvaPeriodicity'][quote.tvaPeriodicity]}</p>
                    </div>
                  }
                </div>

              </section>

              <section>
                
                <h3>Documents</h3>

                <Button
                  onClick={() => setFileModaleIsActive(true)}
                  primary
                  >ENVOYER UN DOCUMENT
                </Button>

                <Button
                  onClick={() => getMissionLetterAction(dispatch , quote._id)}
                  primary
                  className={styles['btn-doc']}
                  >
                  VOIR LA LETTRE DE MISSION
                </Button>
                
                <div className={`row ${styles.documents}`}>
                  {token && quote?.missionLetter?._id &&
                    <div className="col-2">
                      <p className={styles.label}>Lettre de mission</p>
                      <div className={styles.doc}>
                        <a href={`${API_URL}/file/download/${quote.missionLetter._id}?token=${token}`} target="_blank" rel="noreferrer">
                          {quote.missionLetter.title}
                        </a>
                        <button onClick={() => deleteFile("missionLetter" , quote.missionLetter._id)}>
                          <MdDelete size={18}/>
                        </button>
                      </div>
                    </div>
                  }
                  {token && quote?.contract?._id &&
                    <div className="col-2">
                      <p className={styles.label}>Contrat</p>
                      <div className={styles.doc}>
                        <a href={`${API_URL}/file/download/${quote.contract._id}?token=${token}`} target="_blank" rel="noreferrer">
                          {quote.contract.title}
                        </a>
                        <button onClick={() => deleteFile("contract" , quote.contract._id)}>
                          <MdDelete size={18}/>
                        </button>
                      </div>
                    </div>
                  }
                </div>

              </section>
            </div>
            {quote?.client &&
            <section className={styles.contact}>
              <h3>Contacts</h3>
              {quote?.client?.adress?.street && <p><MdHome size={18}/>  {quote?.client?.adress?.street}<br/>{quote?.client?.adress?.cp} {quote?.client?.adress?.city}</p> }
              <a href={`tel:${quote?.client?.tel}`}><MdPhoneForwarded size={18}/> {quote?.client?.tel}</a>
              <a href={`mailto:${quote?.client?.email}`}><MdMail size={18}/> {quote?.client?.email}</a>
              {/* <Link to={`/admin/cases/${quote?.client?.case}/${quote?.client?._id}`} ><HiUser size={20}/> voir le client</Link> */}
            </section>
            }
          </main>
        </>
      }
      <Modale
        isOpen={fileModaleIsActive}
        modaleToggle={setFileModaleIsActive}
        >
        {fileModaleIsActive && 
          <DocumentModale 
            setModaleIsActive={setFileModaleIsActive}
            quote={quote}
        />}
      </Modale>
    </div>
  )
}
