import React , { useEffect, useCallback , useState } from 'react';
import { Link , useLocation, useParams } from 'react-router-dom';
import { MdPhoneForwarded , MdHome , MdMail } from 'react-icons/md';
import { HiArrowLeft  } from 'react-icons/hi';
import { FaPencilAlt } from 'react-icons/fa';
import { GoPlus , GoPin } from 'react-icons/go';

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { getCaseAction } from '../../../../../actions/casesActions';

import Modale from '../../../../partials/Modale/Modale';
import CaseModale from '../../../../partials/CaseModale/CaseModale';

import styles from './CasePage.module.scss';
import Button from '../../../../partials/Button/Button';
import EntityModale from '../../../../partials/EntityModale/EntityModale';
import { getEntityAction, putEntityAction } from '../../../../../actions/entityActions';
import { RESET_QUOTE } from '../../../../../actions/types';
import DocumentModale from '../../../../partials/DocumentModale/DocumentModale';
import DocumentRow from '../../../../partials/DocumentRow/DocumentRow';
import { deleteFileAction } from '../../../../../actions/filesActions';
import { format } from 'date-fns';
import MeetModale from '../../../../partials/MeetModale/MeetModale';
import { getManageAction, getUsersListAction } from '../../../../../actions/usersActions';
import UserModale from '../../../../partials/UserModale/UserModale';

export default function CasePage() {

  const [ context , dispatch ] = useGlobalContext();

  const { caseId , entityId } = useParams();
  const location = useLocation();
  const caseData = context.cases.case;
  const { entity, message } = context.cases;

  const [ isCaseModale , setIsCaseModale ] = useState();
  const [ isEntityModale , setIsEntityModale ] = useState();
  const [ isFileModale , setIsFileModale ] = useState();
  const [ isMeetModale , setIsMeetModale ] = useState();
  const [ isUserModale , setIsUserModale ] = useState();
  const [ isLoading, setLoading ] = useState(true);
  
  const getData = useCallback(() => {
    getCaseAction(dispatch, caseId);
    getUsersListAction(dispatch);
    dispatch({
      type: RESET_QUOTE,
    })
  },[dispatch , caseId]);

  const getEntity = useCallback((id) => {
    getEntityAction(dispatch, id);
    setLoading(true);
  },[dispatch]);

  function deleteFile(id) {
    let files = entity.files.filter(f => f._id !== id).map(f  => f._id);
    putEntityAction(dispatch , entityId , { files : files } );
    deleteFileAction(dispatch , id );
  }


  useEffect(() => {
    if (entityId) {
      getEntity(entityId);
    }
  },[entityId, getEntity]);

  useEffect(() => {
    let timer;
    if(entity) {
      setLoading(true);
      timer = setTimeout(() => {
        setLoading(false);
      }, 100);  
    }
    return () => clearTimeout(timer);

  }, [entity])

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  },[]);


  useEffect(() => {
    if (message === "Entity created !") {
      getData();
    }
    // eslint-disable-next-line
  },[message]);

  return (
    <>
      <div className={`page-content ${styles.container}`}>
        {caseData &&
          <>
            <header className={`${styles.header}`}>
              <Link to={'/admin/cases'} className={styles.back} >
                <HiArrowLeft  size={20}/>  <span className="bold">Retour à la liste</span>
              </Link>
              <div className={styles.content}>
                <div className={`${styles.row} ${styles.case}`}>

                  <div className={`${styles['col-2']}`}>
                    <button
                      onClick={() => setIsCaseModale(caseData)}
                      >
                      <FaPencilAlt />Editer le dossier
                    </button>
                    <h2 className="bold">{caseData?.name}</h2>
                  </div>
                  <div className={`${styles['col-4']}`}>
                    <label>Provenance</label>
                    <p>{caseData?.origin === "Autre" && caseData?.customOrigin ? caseData?.customOrigin : caseData?.origin}</p>
                  </div>
                  <div className={`${styles['col-4']}`}>
                    <label>Utilistateurs</label>
                    {caseData?.users &&
                      <div className={styles.users}>
                        {caseData?.users.filter((u) => u).map((u) => <button
                          onClick={() => setIsUserModale(u)}
                          className={styles.user}>
                          <p>{u?.profile?.lastName?.charAt(0)}.{u?.profile?.firstName?.charAt(0)}</p>
                        </button>)}
                      </div>
                    }
                  </div>
                </div>
                <div className={`${styles.row} ${styles.entities}`}>
                  <div className={styles.list}>
                    {caseData?.entities?.map((e) => <div
                      key={e._id}
                      onClick={() => getEntity(e._id)}
                      className={`${styles.entity} ${entity?._id === e._id ? styles.active : ''}`}>
                      <p>{e.name}</p>
                      {entity?._id === e._id &&
                        <button
                          onClick={() => setIsEntityModale(entity)}
                        >
                          <FaPencilAlt />
                        </button>
                      }
                    </div>)}
                  </div>
                  <div className={styles.add}>
                    <Button
                      type="button"
                      primary
                      onClick={() => setIsEntityModale({ case: caseId })}
                      >
                      <GoPlus />
                    </Button>
                  </div>
                </div>
              </div>
            </header>
            {entity &&
              <main className={`${styles.main} ${!isLoading && styles['slide-in']}`}>
                <div className={styles.content}>

                  <div className={`${styles.row}`}>
                    {entity?.siret &&
                      <div className={`${styles['col-2']} ${styles['info']}`}>
                        <label className={styles['label']}>Nom</label>
                        <p className="bold">{entity.name}</p>

                        <label className={styles['label']}>Numéro de SIRET</label>
                        <p className="bold">{entity.siret}</p>
                      </div>
                    }
                    {entity?.stripeId &&
                      <div className={`${styles['col-2']}`}>
                        <label className={styles['label']}>Abonnement</label>
                        <p className="bold"><button onClick={() => getManageAction(dispatch, entity._id)}>Lien vers la page Stripe</button></p>
                      </div>
                    }
                    <div className={`${styles['col-2']}`}>
                      <div className={styles.contact}>
                        <h3>Contacts</h3>
                        {entity?.adress?.street && <p><MdHome size={18}/>  {entity?.adress?.street}<br/>{entity?.adress?.cp} {entity?.adress?.city}</p> }
                        <a href={`tel:${entity?.tel}`}><MdPhoneForwarded size={18}/> {entity?.tel}</a>
                        <a href={`mailto:${entity?.email}`}><MdMail size={18}/> {entity?.email}</a>
                        <Button
                          primary
                          className={styles['btn-meet']}
                          onClick={() => setIsMeetModale(true)}
                          >
                          Ajouter un rendez-vous
                        </Button>
                      </div>
                    </div>
                  </div>

                  <section>
                    <h3>Devis en cours</h3>
                    <div className={styles.quotes}>
                    {entity?.quotes?.map((q , i) =>
                      <Link key={"link" + i } to={`/admin/quote/view/${q._id}?caseUrl=/admin/cases/${caseId}/${entity._id}`} className={styles.quote}>
                        <p>Devis n°{q.number}</p>
                      </Link>
                    )}
                      <Link key={"add"} to={`/admin/quote/create/${entity._id}`} className={styles['add-quote']}>
                        <GoPlus />
                      </Link>
                    </div>
                  </section>
                  
                  {entity?.appointments?.length > 0 && 
                    <section>
                      <h3>Rendez-vous</h3>
                      <div className={styles.quotes}>
                      {entity.appointments.map((a) =>
                        <Link key={a._id} to={`/admin/meet/${a._id}`} className={styles.quote}>
                          {a.startDate && <p>{format(new Date(a.startDate) , 'dd/MM/yyyy HH:mm')}</p>}
                        </Link>
                      )}
                      </div>
                    </section>
                  }
                  <section>
                    <h3>Documents</h3>
                    <Button
                      onClick={() => setIsFileModale(true)}
                      primary
                      >ENVOYER UN DOCUMENT
                    </Button>
                    <div>
                      {entity?.files?.map((f) => <DocumentRow
                        file={f}
                        styles={styles}
                        deleteFile={deleteFile}
                        />)}
                    </div>
                  </section>

                  {entity?.appointments?.length > 0 &&  entity.appointments[0]?.note &&
                    <section>
                      <h3 className={styles.note}>Note importante <GoPin size={18}/></h3>
                      <p>{entity.appointments[0]?.note}</p>
                    </section>
                  }

                </div>
              </main>
            }
          </>
        }
      </div>
      <Modale
        isOpen={isCaseModale ? true : false}
        modaleToggle={setIsCaseModale}
        >
        {isCaseModale
          && <CaseModale
            data={isCaseModale}
            setModaleIsActive={setIsCaseModale}
          />
        }
      </Modale>
      <Modale
        isOpen={isEntityModale ? true : false}
        modaleToggle={setIsEntityModale}
        >
        {isEntityModale
          && <EntityModale
            entity={isEntityModale}
            caseId={caseId}
            setModaleIsActive={setIsEntityModale}
          />
        }
      </Modale>
      <Modale
        isOpen={isFileModale}
        modaleToggle={setIsFileModale}
        >
        {isFileModale && 
          <DocumentModale 
            setModaleIsActive={setIsFileModale}
            entity={entity}
        />}

      </Modale>
      <Modale
        isOpen={isMeetModale}
        modaleToggle={setIsMeetModale}
        >
        {isMeetModale && 
          <MeetModale
            closeModale={setIsMeetModale}
            client={entity._id}
        />}

      </Modale>
      <Modale
        isOpen={isUserModale ? true : false}
        modaleToggle={setIsUserModale}
        >
        {isUserModale
          && <UserModale
            user={isUserModale}
            setModaleIsActive={setIsUserModale}
            isCase
          />
        }
    </Modale>
    </>
  )
}
