import {
  POST_FILE,
  ERROR_UPLOAD,
  DELETE_FILE
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  file: null,
};

export default function filesReducer(state = initialState, action) {
  switch (action.type) {

    case POST_FILE:
      return {...state , file: action.payload }
    
    case DELETE_FILE:
      return {...state , file: action.payload.file , message: action.payload.message }
    
    case ERROR_UPLOAD:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}