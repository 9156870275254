import { getData , postData, putData } from './index';

import {
  ERROR_CASES,
  GET_ENTITY,
  PUT_ENTITY,
  POST_ENTITY,
  SET_TOAST,
  // POST_ENTITY,
} from "./types";


export async function getEntityAction(dispatch , id) {
  
  let url = `/entity/${id}` ;
  var res;

  await getData(ERROR_CASES, url, dispatch , true ).then((response)=>{
    res = response.entity;
  });

  dispatch({
    type : GET_ENTITY,
    payload : res,
  });

};

export async function postEntityAction(dispatch , data ) {
  let url = "/entity" ;
  let res;

  await postData( POST_ENTITY , ERROR_CASES ,  url , dispatch, data , true ).then((response)=>{
    res = response.entity;
  });

  dispatch({
    type : POST_ENTITY,
    payload : res
  });

  dispatch({
    type : SET_TOAST,
    payload : {
      type: "success",
      message: "l'entité a été créé"
    }
  });

};

export async function putEntityAction(dispatch , id , data ) {
  let url = `/entity/${id}` ;
  var res;

  await putData(PUT_ENTITY, ERROR_CASES ,  url , dispatch, data , true ).then((response)=>{
    res = response.entity;
  });

  dispatch({
    type : PUT_ENTITY,
    payload : res
  });

};

