import React, { useRef , useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';
import styles from './SelectEntity.module.scss';

export default function SelectEntity() {
  const context = useGlobalContext()[0];
  const [isOpen, setIsOpen ] = useState(false);
  const { entity } = context.cases;
  const { userAuth } = context.auth;
  const entities = userAuth?.entities.filter((e) => e._id !== entity?._id);
  const isEntities = entities?.length > 0 ? true : false;
  const selectRef = useRef();

  return (
    <div className={styles.container}>
      <div className={styles.entity}>
        <p>{entity?.name}</p>
        {isEntities
          && <button onClick={() => setIsOpen(!isOpen)} className={isOpen ? styles['active'] : ''}>
          <FiChevronDown />
        </button>
        } 
      </div>
      <div className={styles.select} style={{ height: isOpen ? selectRef?.current?.clientHeight : 0 }}>
        <div ref={selectRef}>
          {entities?.map((e) => <Link key={e._id} to={`/member/tools/${e._id}`}>
            {e.name}
          </Link>)}
        </div>
      </div>
    </div>
  )
}
