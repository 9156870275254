import React from 'react';
import { format } from 'date-fns';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import styles from './MeetInfo.module.scss';

export default function MeetInfo({ appointment }) {

  const context = useGlobalContext()[0];

  const isActive = context.auth?.toast?.message === "Votre compte est activé" ? true : false

  var options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};

  return (
    <>
      {!isActive &&
        <div className={styles.container}>
          <div className={styles.info}>
            {appointment?.startDate &&  
              <p>
                Votre rendez-vous est prévu<br/>pour le <span className="bold">{new Intl.DateTimeFormat('fr', options ).format(new Date(appointment.startDate))} à {format(new Date(appointment.startDate) , 'HH:mm')}</span>
              </p>
            }
          </div>
        </div>
      }
    </>
  )
}
