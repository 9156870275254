import React , { useState ,useEffect , useCallback } from 'react';
import { Link , useLocation, useParams } from 'react-router-dom';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { getQuoteAction , postQuoteAction, putQuoteAction } from '../../../../../actions/quotesActions';

import { quotesOptions , questions } from '../../../../../utils/constants';

import { HiArrowLeft , HiUser } from 'react-icons/hi';
import { MdPhoneForwarded , MdMail } from 'react-icons/md';

import Question from './Question/Question';
import Button from '../../../../partials/Button/Button';
import Modale from '../../../../partials/Modale/Modale';
import Input from '../../../../partials/Form/Input/Input';
import DocumentModale from '../../../../partials/DocumentModale/DocumentModale';
import DatePicker from '../../../../partials/DatePicker/DatePicker';

import styles from './QuotePage.module.scss';
import CustomSelect from '../../../../partials/Form/Select/CustomSelect';
import { getEntityAction } from '../../../../../actions/entityActions';
import { ERROR_QUOTES, SET_TOAST } from '../../../../../actions/types';

export default function QuotePage() {

  const [ context , dispatch ] = useGlobalContext();
  const { quoteId , entityId } = useParams();
  const { search } = useLocation();

  const { quote , error } = context.quotes ;
  const { entity } = context.cases;
  const [ quoteForm , setQuoteForm ] = useState();
  const [ formData , setFormData ] = useState({});

  const companyTypeOptions = [ "EURL" ,"SARL" ,"SAS", "SASU" ,"Entreprise individuelle" ,"SELARL" ,"SELAS" ,"Association" , "SCI à l'IR" ,"SCI à l'IS" , "SNC" ,"Autre : à préciser" ].map(t => ({ value: t , label: t }))

  const activitiesOptions = [
    { label: "Activité le location immobiliére", value: "location" },
    { label: "Profession libérale", value: "liberal"},
    { label: "Prestataire de service , Freelance, Consultant", value: "freelance"},
    { label: "Artisan , Commerçant", value: "artisan"},
    { label: "Micro entrepreneur", value: "entrepreneur"},
    { label: "Autres", value: "other"},
  ]

  const [ fileModaleIsActive  , setFileModaleIsActive ] = useState();
  
  const getData = useCallback(() => {
    if (quoteId) getQuoteAction(dispatch , quoteId);
    getEntityAction(dispatch, entityId);
  },[dispatch , quoteId , entityId]);

  useEffect(() => {
    getData()
    return () => {
      setQuoteForm();
      setFormData();
    }
  },[getData]);

  useEffect(() => {
    if (quote?._id) {
      setQuoteForm(quote)
    }
  },[quote]);

  function handleChangeState(val) {
    putQuoteAction(dispatch , quote._id , { state: val });
  }

  function handleChange( key , value) {

    let data = Object.assign({} , {...quoteForm } );
    let form = Object.assign({} , {...formData } );

    data[key] = value
    form[key] = value

    if (key === "startDate" || key === "closingDate" ) {
      let date = new Date(value)
      date.setUTCMilliseconds(0)
      date.setUTCMinutes(0)
      date.setUTCHours(0)

      data[key] = date.toUTCString()
      form[key] = date.toUTCString()

    }

    if (key === "turnover" && value === "other") {
      form.turnoverValue = "";
      data.turnoverValue = "";
    }

    if (key === "activity") {
      form.subActivity = "";
      form.administrative = "";
      form.turnoverValue = null;
      form.turnover = "";
      form.propertyNumber = null;
      form.tva = null;
      form.employee = null;
      form.employeePrice = null;
      form.amountHT = 0;
      
      data.subActivity = "";
      data.administrative = "";
      data.turnoverValue = null;
      data.turnover = "";
      data.propertyNumber = null;
      data.tva = null;
      data.employee = null;
      data.employeePrice = null;
      data.amountHT = 0;

    }

    setFormData(form);
    setQuoteForm(data);

  };

  function submitQuote() {
    const values = {...formData, client: entityId }
    if (quote?._id) {
      putQuoteAction(dispatch , quoteId , values );
    } else {
      postQuoteAction(dispatch, values);
    }
  }

  useEffect(() => {
    if (error?.data?.error) {
      dispatch({
        type : SET_TOAST,
        payload : {
          type: "error",
          message: "Tout les champs sont requis"
        }
      });
      dispatch({
        type : ERROR_QUOTES,
        payload : null,
      });
    }
  }, [error , dispatch])

  return (

    <div className={`page-content ${styles.container} `}>
        <form>
          <header className={`${styles.header}`}>
            <Link to={quoteId ? `/admin/quote/view/${quoteId}${search}`: `/admin/cases/${entity.case}/${entityId}`} className={styles.back} >
              <HiArrowLeft  size={20}/>  <span className="bold">{quoteId ? 'Retour au devis' : 'Retour au dossier' }</span>
            </Link>
            <div className={styles.state}>
              {quotesOptions.find(opt => opt.value.name === quote?.state) &&
                <CustomSelect
                  defaultValue={quotesOptions.find(opt => opt.value.name === quote?.state)}
                  handleChange={(val) => handleChangeState(val.value.name)}
                  options={quotesOptions}
                  />
              }
            </div>
            <div className={styles.content}>
              <Button
                onClick={() => submitQuote()}
                primary
                >
                ENREGISTRER
              </Button>

              <div className={`${styles.row} ${styles.profile} ${styles.quote}`}>
                <div className={`${styles['col-2']} ${styles.number} ${styles.edit}`}>
                  <p className={styles.label}>Devis n°</p>
                  <h1 className="bold">{quote?.number ? quote.number : "Nouveau devis"}</h1>
                </div>
              </div>

              <div className={`${styles.row} ${styles.profile} ${styles.quote}`}>

                <div className={styles['col-3']}>
                  <p className={styles.label}>Nom</p>
                  <Link to={`/admin/cases/${entity?.case}/${entity?._id}`} >
                    <p className="bold">{entity?.name}</p>
                  </Link>
                </div>

                {entity?.siret &&
                  <div className={styles['col-3']}>
                    <p className={styles.label}>N°Siret</p>
                    <p className="bold">{entity?.siret}</p>
                  </div>
                }

              </div>

              <div className={`${styles['row']} ${styles.profile} ${styles.quote}`}>
                <div className={`${styles['col-3']} ${styles.input} ${styles.edit}`}>
                  <Input
                    label={"Montant HT"}
                    type={"number"}
                    defaultValue={quote?.amountHT ? quote.amountHT : 0 }
                    value={quote?.amountHT}
                    onChange={(value) => handleChange('amountHT' , parseFloat(value))}
                    />
                  </div>
                {quoteForm &&
                <>
                  <div className={`${styles['col-3']} ${styles.input} ${styles.edit}`}>
                    <CustomSelect
                      label={"Précision de l'activité"}
                      defaultValue={quote?.activity && activitiesOptions.find(item => item.value ===  quote.activity) ? activitiesOptions.find(item => item.value ===  quote.activity) : null}
                      handleChange={(opt) => handleChange('activity' , opt.value)}
                      options={activitiesOptions}
                      withErrors={false}
                      />
                  </div>
                  <div className={`${styles['col-3']} ${styles.input} ${styles.edit}`}>
                    <CustomSelect
                      label={"Type de société"}
                      defaultValue={companyTypeOptions?.find(item => item.value === quote?.companyType) || null}
                      handleChange={(opt) => handleChange('companyType' , opt.value)}
                      options={companyTypeOptions}
                      withErrors={false}
                      />
                  </div>
                  {quoteForm?.companyType === 'Autre : à préciser' &&
                    <div className={`${styles['col-3']} ${styles.input} ${styles.edit}`}>
                      <Input
                        label={"Autre type de société"}
                        defaultValue={quoteForm?.customCompanyType ? quoteForm.customCompanyType : "" }
                        onChange={(value) => handleChange('customCompanyType' , value)}
                        withErrors={false}
                      />
                    </div>
                  }
                  </>
                  }
              </div>

            </div>
          </header>
          <main className={`${styles.main} ${styles.edit}`}>
            <div className={styles.content}>
              <section className={styles.questions}>
                <div className={`${styles.row}`}>
                  <div className={`${styles.dates}`}>
                    <DatePicker
                      label={"Date de début de mission"}
                      handleChange={(val) => handleChange("startDate" ,val)}
                      withErrors={true}
                      date={quote?.startDate ? new Date(quote?.startDate): null }
                      />
                  </div>
                  <div className={`${styles.dates}`}>
                    <DatePicker
                      label={"Date de clôture"}
                      handleChange={(val) => handleChange("closingDate" ,val)}
                      withErrors={true}
                      date={quote?.closingDate ? new Date(quote?.closingDate): null }
                      />
                  </div>
                </div>
              </section>
              <section className={styles.questions}>
                {quoteForm && quoteForm.activity !== "other" && questions.filter(item => item.activities.find(a => a === quoteForm.activity || a === quoteForm.turnover ) ).map((item , i) =>
                  <Question
                    key={`question-${i}`}
                    question={item.question}
                    type={item.type}
                    value={quoteForm && quoteForm[item.key]}
                    options={item.options}
                    handleChange={(val) => handleChange( item.key , val )}
                    />
                )}
              </section>
            </div>
            {quote?.client &&
            <section className={styles.contact}>
              <h3>Contacts</h3>
              <a href={`tel:${quote?.client?.tel}`}><MdPhoneForwarded size={18}/> {quote?.client?.tel}</a>
              <a href={`mailto:${quote?.client?.email}`}><MdMail size={18}/> {quote?.client?.email}</a>
              <Link to={`/admin/cases/${quote?.client?.case}/${quote?.client?._id}`} ><HiUser size={20}/> voir le client</Link>
            </section>
            }
          </main>
        </form>

      <Modale
        isOpen={fileModaleIsActive}
        modaleToggle={setFileModaleIsActive}
        >
        {fileModaleIsActive && 
          <DocumentModale 
            setModaleIsActive={setFileModaleIsActive}
            quote={quote}
        />}
      </Modale>
    </div>
  )
}
